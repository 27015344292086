<template>
  <div style="min-height: 300px" class="relative">
    <div v-if="loading">
      <div
        class="
          col-span-6
          sm:col-span-3
          xl:col-span-2
          flex flex-col
          justify-end
          items-center
        "
      >
        <LoadingIcon icon="three-dots" class="w-8" />
      </div>
      <div class="col-span-1">
        <AlertSuccess v-if="alertSuccess" :message="messageAlert" />
        <AlertFailed v-if="alertFailed" :message="messageAlert" />
      </div>
      <div class="text-center mt-2">
        Data Sedang Di Proses, Silahkan Tunggu...
      </div>
    </div>
    <table v-if="!loading" class="table">
      <thead>
        <tr class="bg-gray-700 dark:bg-dark-1 text-white">
          <th class="whitespace-nowrap">#</th>
          <th class="whitespace-nowrap">KODE MK</th>
          <th class="whitespace-nowrap">NAMA MK</th>
          <th class="whitespace-nowrap">SKS</th>
          <th class="whitespace-nowrap">NILAI HURUF</th>
          <th class="whitespace-nowrap">NILAI ANGKA</th>
          <th class="whitespace-nowrap">NILAI INDEX</th>
          <th class="whitespace-nowrap">MUTU</th>
          <th v-if="isaction === true" class="whitespace-nowrap">AKSI</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in dataNilai"
          :key="index"
          class="hover:bg-gray-200 dark:hover:bg-gray-800"
        >
          <td class="border-b dark:border-dark-5">{{ index + 1 }}</td>
          <td class="border-b dark:border-dark-5">
            {{ item.kodeMk }}
          </td>
          <td class="border-b dark:border-dark-5">
            {{ item.namaMk }}
          </td>
          <td class="border-b dark:border-dark-5">{{ item?.sks }}</td>
          <td class="border-b dark:border-dark-5">{{ item?.nilaiHuruf }}</td>
          <td class="border-b dark:border-dark-5">{{ item?.nilaiAngka }}</td>
          <td class="border-b dark:border-dark-5">{{ item?.nilaiIndex }}</td>
          <td class="border-b dark:border-dark-5">{{ item?.mutu }}</td>
          <td v-if="isaction === true" class="border-b dark:border-dark-5">
            <button class="btn btn-danger" @click="destroy(item?.id)">
              Hapus
            </button>
          </td>
        </tr>
        <tr v-if="dataNilai.length === 0">
          <td colspan="8" class="text-center">Tidak Ada Nilai</td>
        </tr>
      </tbody>
      <tfoot v-if="dataNilai.length !== 0">
        <tr class="bg-gray-200 text-gray-700 dark:bg-gray-800 dark:text-white">
          <th class="whitespace-nowrap" colspan="3">Jumlah</th>
          <th class="whitespace-nowrap">{{ totalSks }} SKS</th>
          <th class="whitespace-nowrap"></th>
          <th class="whitespace-nowrap"></th>
          <th class="whitespace-nowrap">Nilai Index</th>
          <th class="whitespace-nowrap">
            {{
              Math.round(
                (Number.parseFloat(totalMutu) + Number.EPSILON) * 100
              ) / 100
            }}
          </th>
        </tr>
        <tr class="bg-gray-300 text-gray-700 dark:bg-gray-800 dark:text-white">
          <th class="whitespace-nowrap" colspan="7">
            Indeks Prestasi Semester (IPS)
          </th>
          <th class="whitespace-nowrap">{{ ipk }}</th>
        </tr>
      </tfoot>
    </table>

    <div id="delete-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
              <div class="text-3xl mt-5">Are you sure?</div>
              <div class="text-gray-600 mt-2">
                Do you really want to delete these records? <br />
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button
                type="button"
                data-dismiss="modal"
                class="
                  btn btn-outline-secondary
                  w-24
                  dark:border-dark-5 dark:text-gray-300
                  mr-1
                "
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-danger w-24"
                @click="hapusData"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { perkuliahan } from "@/services/models";
import AlertSuccess from "../../components/alerts/AlertSuccess.vue";
import AlertFailed from "../../components/alerts/AlertFailed.vue";
export default {
  name: "NilaiSemester",
  components: {
    AlertSuccess,
    AlertFailed,
  },
  props: {
    kodemahasiswa: {
      type: String,
      default: "",
    },
    detailmahasiswa: {
      type: Object,
      default: null,
    },
    semester: {
      type: String,
      default: "",
    },
    isaction: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      dataNilai: [],
      totalSks: 0,
      totalMutu: 0,
      ipk: 0,
      alertSuccess: false,
      alertFailed: false,
      messageAlert: "",
      id: null,
    };
  },
  watch: {
    detailmahasiswa: {
      handler: function () {
        this.getNilaiSemester();
      },
      immediate: true,
    },
    semester: {
      handler: function () {
        this.getNilaiSemester();
      },
      immediate: true,
    },
  },
  methods: {
    getNilaiSemester() {
      if (this.loading === true) return true;
      if (this.semester.toString() === "0") return true;
      this.loading = true;
      this.dataNilai = [];
      this.totalSks = 0;
      this.totalMutu = 0;
      perkuliahan.NilaiSemesterMahasiswa.getCustomPath(
        `getNilaiMahasiswa/${this.detailmahasiswa?.id_reg_pd}/${this.semester}`
      )
        .then((res) => {
          if (res.status === 200) {
            if (res.data?.data) {
              res.data.data.forEach((element) => {
                let mutu = (
                  element?.nilai_indeks * element?.kelas_kuliah?.matkul?.sks_mk
                ).toFixed(2);
                this.dataNilai.push({
                  kodeMk: element?.kelas_kuliah?.matkul?.kode_mk,
                  namaMk: element?.kelas_kuliah?.matkul?.nm_mk?.toUpperCase(),
                  sks: element?.kelas_kuliah?.matkul?.sks_mk,
                  nilaiHuruf: element?.nilai_huruf,
                  nilaiAngka: element?.nilai_angka,
                  nilaiIndex: element?.nilai_indeks,
                  mutu: mutu,
                  id: element?.id,
                  id_reg_pd: element?.reg_pd?.id_reg_pd,
                  id_mk: element?.kelas_kuliah?.id_mk,
                });
                this.totalSks += parseInt(
                  element?.kelas_kuliah?.matkul?.sks_mk
                );
                this.totalMutu += Number.parseFloat(mutu);
              });
              /*this.ipk = (
                Number.parseFloat(this.totalMutu) /
                Number.parseInt(this.totalSks)
              ).toFixed(2);*/
              let _ipk =
                Number.parseFloat(this.totalMutu) /
                Number.parseInt(this.totalSks);
              this.ipk = Math.round((_ipk + Number.EPSILON) * 100) / 100;
              this.loading = false;
            }
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    destroy(id) {
      this.id = id;
      cash("#delete-modal").modal("show");
    },
    hapusData() {
      let data = this.dataNilai.filter((item) => item.id === this.id);
      let model = {
        id: data[0]["id"],
        id_reg_pd: data[0]["id_reg_pd"],
        id_mk: data[0]["id_mk"],
      };
      console.log(model);
      perkuliahan.NilaiSemesterMahasiswa.postCustomPath(
        "deleteNilaiSemesterMhs",
        model
      ).then((res) => {
        console.log(res);
        if (res.status === 200) {
          this.alertSuccess = true;
          this.messageAlert = res?.data?.message;
          this.getNilaiSemester();
        } else {
          this.loading = false;
        }
      });
      cash("#delete-modal").modal("hide");
    },
  },
};
</script>

<style scoped>
.block-loading {
  display: flex;
  position: absolute;
  background: #ffffffad;
  height: 100%;
  width: 100%;
  z-index: 99;
  align-items: center;
  font-weight: bold;
  font-size: 32px;
  flex-direction: column;
  padding-top: 5rem;
}
</style>
