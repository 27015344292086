<template>
  <div class="relative">
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Tambah Akun</h2>
    </div>

    <div class="intro-y box p-5 mt-5">
      <div class="grid sm:grid-cols-2 grid-cols-1 gap-2">
        <div class="mb-2">
          <label class="form-label">Kode Akun</label>
          <input v-model="kode_akun" class="form-control" />
          <span
            v-if="
              messageValidator?.kd_account &&
              messageValidator?.kd_account.length > 0
            "
            class="text-red-600"
            >{{ messageValidator?.kd_account[0] }}</span
          >
        </div>

        <div class="mb-2">
          <label class="form-label">Nama Akun</label>
          <input v-model="nm_akun" class="form-control" />
          <span
            v-if="
              messageValidator?.nm_akun &&
              messageValidator?.nm_akun.length > 0
            "
            class="text-red-600"
            >{{ messageValidator?.nm_akun[0] }}</span
          >
        </div>
      </div>

      <div class="my-3">
        <button class="btn btn-primary w-full" @click="simpanAkun">
          SIMPAN
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { keuangan } from "@/services/models";
import router from "@/router";

export default defineComponent({
  data() {
    return {
      kode_akun: "",
      nm_akun: "",
      messageValidator: [],
    };
  },
  methods: {
    simpanAkun() {
      let val = {
        kd_account: this.kode_akun,
        nm_akun: this.nm_akun,
      };
      keuangan.Akun.insertData(val).then((res) => {
        if (res.data.code === 200) {
          router.push({
            name: "akun",
          });
        } else {
          this.messageValidator = res.data.message;
        }
      });
    },
  },
});
</script>
