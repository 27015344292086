<template>
  <div>
    <a
      :id="tabNavId"
      data-toggle="tab"
      :data-target="'#' + tabNavId + '-tab'"
      href="javascript:"
      class="py-4 sm:mr-8 flex items-center"
      role="tab"
      :aria-controls="tabLabel"
      aria-selected="true"
    >
      <main>
        <slot></slot>
      </main>
    </a>
  </div>
</template>

<script>
export default {
  name: "TabNav",
  props: {
    tabNavId: {
      default: "",
      type: String,
    },
    tabLabel: {
      default: "",
      type: String,
    },
  },
};
</script>

<style scoped></style>
