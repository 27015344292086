<template>
  <div
    class="block -mx-5 -my-3 p-2 border-t-2 border-yellow-200"
    style="min-width: 200px"
  >
    <div class="flex">
      <div class="flex-1">
        <div class="font-medium whitespace-normal text-xs text-gray-100">
          {{ detailmk.nama_mk }}
        </div>
      </div>
      <div class="flex-initial ml-2">
        <router-link
          v-if="isButton"
          :to="{ name: 'konfirmasi-mengajar', params: { id: detailmk.kelas } }"
          class="btn bg-transparent btn-sm text-xs text-white hover:bg-green-600 pull-right"
        >
          <AwardIcon />
          {{ detailmk.kelas }}
        </router-link>
        <span v-if="!isButton" class="font-medium text-lg text-white">
          {{ detailmk.kelas }}
        </span>
      </div>
    </div>

    <div class="flex mt-1 pt-1 border-t border-gray-100">
      <div class="flex-1 font-medium text-gray-100 text-xs whitespace-nowrap">
        {{ detailmk.kodeMK }}
      </div>
      <div class="flex-1 font-medium text-red-300 text-xs whitespace-nowrap">
        {{ detailmk.sks_mk }} SKS
      </div>
      <div class="flex-1 font-medium text-yellow-300 text-xs whitespace-nowrap">
        {{ detailmk.ruang }}
      </div>
    </div>
    <div class="flex mt-1 pt-1 pb-2 border-t border-gray-100">
      <div
        class="flex-1 text-gray-300 text-xs whitespace-normal"
        :class="detailmk?.kd_dosen ? 'h-8' : ''"
      >
        {{ detailmk?.nm_dosen }}
        <span v-if="detailmk?.kd_dosen">({{ detailmk?.kd_dosen }})</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "JadwalKuliahTableItem",
  props: {
    detailmk: {
      type: Object,
      default: () => ({}),
    },
    isButton: {
      type: Boolean,
      default: false,
    },
    fakultas: {
      type: String,
      default: "1",
    },
  },
};
</script>

<style scoped></style>
