<template>
  <div>
    <div>
      <label class="mb-1 form-label">Pilih Prodi</label>
      <select v-model="current_IdRegPD" class="form-select mb-3 box">
        <option value="0">Pilih Program Studi</option>
        <option v-for="reg in regPD" :key="reg.id" :value="reg.id_reg_pd">
          {{ reg?.sms?.jenjang_pendidikan.nm_jenj_didik }}
          {{ reg?.sms?.nm_lemb }} - {{ reg?.satuan_pendidikan?.nm_lemb }}
        </option>
      </select>
    </div>

    <div class="flex">
      <TailSelectSelector
        :model="cJenisAkt"
        :list-options="listJenisAktMhs"
        :show-label="'Jenis Akt Kuliah Mhs'"
        @cbox-select="selectJenisAkt"
      ></TailSelectSelector>
      <div>
        <label class="form-label">&nbsp;</label>
        <button class="btn btn-warning mt-7" @click="cJenisAkt = 0">
          <XCircleIcon class="w-4 h-4 mr-2" />
          Reset
        </button>
      </div>
    </div>

    <table class="table">
      <thead>
        <tr class="bg-gray-700 dark:bg-dark-1 text-white">
          <th class="whitespace-nowrap">#</th>
          <th class="whitespace-nowrap">Semester</th>
          <th class="whitespace-nowrap">Judul Akt</th>
          <th class="whitespace-nowrap">SK</th>
          <th class="whitespace-nowrap">Jenis Akt</th>
          <th class="whitespace-nowrap">Dosen</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(pd, key) in aktMhs" :key="pd.id" class="hover:bg-gray-200">
          <td class="border-b dark:border-dark-5">{{ ++key }}</td>

          <td class="border-b dark:border-dark-5">
            {{ pd?.semester?.nm_smt }}
          </td>
          <td class="border-b dark:border-dark-5">
            {{ pd?.status_mahasiswa?.nm_stat_mhs }}
          </td>
          <td class="border-b dark:border-dark-5">
            {{ parseFloat(pd.ips).toFixed(2) }}
          </td>
          <td class="border-b dark:border-dark-5">
            {{ parseFloat(pd.ipk).toFixed(2) }}
          </td>
          <td class="border-b dark:border-dark-5">
            {{ parseInt(pd.sks_smt) }}
          </td>
          <td class="border-b dark:border-dark-5">
            {{ parseInt(pd.sks_total) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { perkuliahan, ref } from "@/services/models";
import TailSelectSelector from "@/components/form/TailSelectSelector";

export default {
  name: "DataAktKuliah",
  components: { TailSelectSelector },
  props: {
    regPD: {
      type: Object,
      default: null,
    },
    idRegPD: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      detailKeluar: null,
      keluar: null,
      aktMhs: null,
      current_IdRegPD: null,
      current_IdJenisAkt: null,
      listJenisAktMhs: [],
      cJenisAkt: 0,
    };
  },
  watch: {
    regPD: {
      handler: function () {},
      immediate: true,
    },
    idRegPD: {
      handler: function (newid) {
        if (newid !== null) {
          this.current_IdRegPD = newid;
        }
      },
      immediate: true,
    },
    current_IdRegPD: {
      handler: function (newid) {
        let that = this;
        if (newid != null && newid != 0) {
          perkuliahan.AnggotaAktMhs.fetchUrl("get/" + newid).then((result) => {
            if (result?.status === 200) {
              that.aktMhs = result.data.data;
            }
          });
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.getListJenisAkt();
  },
  methods: {
    setDetailKeluar(keluar, detail) {
      this.detailKeluar = detail;
      this.keluar = keluar;
    },
    getListJenisAkt() {
      let list = [];
      let defaultOpt = {
        id: 0,
        value: "",
        text: "Pilih Jenis Aktivitas Mahasiswa",
        selected: true,
      };
      list.push(defaultOpt);
      ref.jenisAktMhs
        .getAll()
        .then((result) => {
          if (result?.status === 200) {
            result?.data?.data.forEach((item) => {
              let newoption = {
                id: item.id_jns_akt_mhs,
                value: item.id_jns_akt_mhs,
                text:
                  item.nm_jns_akt_mhs +
                  (parseInt(item.a_kegiatan_kampus_merdeka) == 1
                    ? " (Kampus Merderka)"
                    : ""),
                selected: item.id_jns_akt_mhs == this.current_IdJenisAkt,
              };
              list.push(newoption);
            });
          }
        })
        .finally(() => {
          this.listJenisAktMhs = list;
        });
    },
    selectJenisAkt(curr, id) {
      let that = this;
      if (this.current_IdRegPD == "0") return;
      this.cJenisAkt = id;
      if (id > 0) {
        perkuliahan.AktMhs.fetchUrl(
          "get/" + this.current_IdRegPD + "/" + parseInt(id)
        ).then((result) => {
          if (result?.status === 200) {
            that.aktMhs = result.data.data;
          }
        });
      }
    },
  },
};
</script>

<style scoped></style>
