<template>
  <div class="relative" style="min-height: 300px">
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y">
      <LoadingSpinner :is-loading="loadingStatus" />
      <div class="grid grid-cols-12">
        <div class="md:col-span-3 col-span-12 mb-4">
          <label class="form-label">Pilih Semester</label>
          <select
            v-model="semester"
            class="form-control focus:outline-none focus:ring-0"
          >
            <option
              v-for="smtr in listSemester"
              :key="smtr.id"
              :value="smtr.value"
              :selected="smtr.selected"
            >
              {{ smtr.text }}
            </option>
          </select>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="table-report table-report--tabulator"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from "vue";
import feather from "feather-icons";
import { useStore } from "vuex";
import LoadingSpinner from "@/components/loading-spinner/Main";
import { tblTabulator } from "@/utils/tabulator";
import { master, perkuliahan } from "@/services/models";
export default defineComponent({
  components: {
    LoadingSpinner,
  },
  props: {
    detailMahasiswa: {
      type: Object,
      default: null,
    },
    smt: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    let deletingRow = ref();
    const idData = ref();
    const store = useStore();
    const tableRef = ref();
    const tabulator = ref();
    const filter = reactive({
      field: "nm_mk",
      type: "like",
      value: "",
    });
    const accessToken = store.getters["auth/accessToken"];
    const initTabulator = () => {
      let columns = [
        {
          formatter: "responsiveCollapse",
          width: 40,
          minWidth: 30,
          hozAlign: "center",
          resizable: false,
          headerSort: false,
        },
        { formatter: "rownum", hozAlign: "center", width: 40 },
        // For HTML table
        {
          title: "KELAS",
          width: 100,
          field: "kelas",
          hozAlign: "left",
          vertAlign: "middle",
          print: false,
          download: false,
          formatter(cell) {
            let kls = cell.getData().kelas?.nm_kls;
            return `<div>
                <div class="font-small whitespace-nowrap">${kls}
              </div>`;
          },
        },
        {
          title: "MATA KULIAH",
          width: 150,
          field: "kelas",
          hozAlign: "left",
          vertAlign: "middle",
          print: false,
          download: false,
          formatter(cell) {
            let kdMK = cell.getData().kelas?.matkul?.kode_mk;
            let mk = cell.getData().kelas?.matkul?.nm_mk;
            return `<div>
                <div class="font-medium whitespace-nowrap">${kdMK}
                <i data-feather="check-square" class="w-4 h-4"></i>
                </div>
                <div class="text-gray-600 text-xs whitespace-normal">${mk}</div>
              </div>`;
          },
        },

        {
          title: "SKS",
          width: 80,
          field: "sms",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            return parseInt(cell.getData().kelas.sks_mk);
          },
        },

        {
          title: "JADWAL",
          minWidth: 150,
          field: "waktu",
          hozAlign: "left",
          vertAlign: "top",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            let waktu = `<div class="grid grid-cols-2 gap-2">`;
            cell.getData().waktu.forEach((w) => {
              waktu +=
                '<div><div class="text-sm whitespace-nowrap ">' +
                w.hari.hari +
                '</div> <span class="text-gray-600 text-xs whitespace-nowrap">' +
                w.jam[0].jam.substring(0, 5) +
                " - " +
                w.jam[0].selesai.substring(0, 5) +
                "</span></div>";
              let ruang = `<div>
                            <div class="text-xs whitespace-nowrap">${w.ruangan?.nm_ruang}</div>
                            <div class="text-gray-600 text-xs whitespace-normal">${w.ruangan?.gedung?.nm_gedung}</div>
                          </div>`;
              waktu +=
                '<div class="text-sm whitespace-nowrap">' + ruang + "</div>";
            });
            waktu += `</div>`;
            return `<div>${waktu}</div>`;
          },
        },
        {
          title: "DOSEN",
          width: 180,
          field: "kelas",
          hozAlign: "left",
          vertAlign: "middle",
          print: false,
          download: false,
          formatter(cell) {
            let dosen = "";
            if (cell.getData().kelas?.akt_ajar_dosen?.length > 0) {
              cell.getData().kelas?.akt_ajar_dosen?.forEach((d) => {
                dosen += `<div class="font-medium whitespace-nowrap">${d.ptk.sdm.nidn}</div>
                <div class="text-gray-600 text-xs whitespace-normal mb-1">${d.ptk.sdm.nm_sdm}</div>`;
              });
            } else {
              dosen +=
                '<div class="text-yellow-600 text-xs whitespace-normal">Akt. Ajar Kosong</div>';
            }

            return `<div>
                ${dosen}
              </div>`;
          },
        },
        {
          title: "PENGAJAR",
          width: 180,
          field: "KD_PENGGANTI",
          hozAlign: "left",
          vertAlign: "middle",
          print: false,
          download: false,
          formatter(cell) {
            return `<div>
                <div class="font-medium whitespace-nowrap">${
                  cell.getData().dosen_pengajar.KODE
                }
                <i data-feather="check-square" class="w-4 h-4"></i>
                </div>
                <div class="text-gray-600 text-xs whitespace-normal">${
                  cell.getData().dosen_pengajar.NM_DOSEN
                }</div>
              </div>`;
          },
        },
      ];
      tabulator.value = tblTabulator.remoteDataTable(
        tableRef,
        perkuliahan.JadwalKelasKuliah.endpoint +
          "/jadwal_mhs/" +
          props.detailMahasiswa?.id_reg_pd,
        columns,
        true
      );
    };
    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener("resize", () => {
        tabulator.value.redraw();
        feather.replace({
          "stroke-width": 1.5,
        });
      });
    };
    // Filter function
    const onFilter = () => {
      tabulator.value.setFilter(filter.field, filter.type, filter.value);
    };
    // On reset filter
    const onResetFilter = () => {
      filter.field = "nm_mk";
      filter.type = "like";
      filter.value = "";
      onFilter();
    };
    // Print
    const onPrint = () => {
      tabulator.value.print();
    };
    onMounted(() => {
      initTabulator();
      reInitOnResizeWindow();
    });
    return {
      store,
      tabulator,
      tableRef,
      filter,
      onFilter,
      onResetFilter,
      onPrint,
      accessToken,
      deletingRow,
      idData,
    };
  },
  data() {
    return {
      semester: null,
      listSemester: [],
    };
  },
  computed: {
    loadingStatus() {
      return this.$store.state.main.loadingStatus;
    },
    currentSemester() {
      return this.$store.state.main.currentSemester;
    },
  },
  watch: {
    //update table if data changes
    dataTable: {
      handler: function (newData) {
        if (
          this.tabulator.getDataCount() !== newData.length &&
          newData.length > 0
        ) {
          this.replaceData(newData);
        } else if (newData.length < this.tabulator.getDataCount()) {
          this.replaceData(newData);
        }
      },
      deep: true,
    },
    smt: {
      handler: function (newval) {
        this.semester = newval;
      },
      immediate: true,
    },
    currentSemester: {
      handler: function () {
        this.$store.state.main.currentSemester;
      },
    },

    semester: {
      handler: function () {
        this.getItems();
      },
    },
  },
  beforeMount() {
    this.fetchSemester();
  },
  methods: {
    getItems() {
      this.tabulator.setData(
        perkuliahan.JadwalKelasKuliah.endpoint +
          "/jadwal_mhs/" +
          this.detailMahasiswa?.id_reg_pd,
        { semester: this.semester },
        tblTabulator.ajaxConfig()
      );
    },
    fetchSemester() {
      let that = this;
      this.listSemester = [];
      this.listSemester.push({
        id: "0",
        value: "0",
        text: "Pilih Semester",
        selected: false,
      });
      let path =
        this.detailMahasiswa?.id_reg_pd === undefined
          ? "smt_kuliah_mhs"
          : "smt_kuliah_mhs/" + this.detailMahasiswa?.id_reg_pd;

      master.SemesterAkad.getCustomPath(path).then((result) => {
        if (result.status === 200) {
          result.data.data.forEach((item) => {
            let newoption = {
              id: item.id_smt,
              value: item.id_smt,
              text: item.nm_smt,
              selected: that.semester.toString() === item.id_smt,
            };

            if (newoption.selected === true)
              that.semester = item.id_smt.toString();
            that.listSemester.push(newoption);
          });
        }
      });
    },
  },
});
</script>
