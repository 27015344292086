<template>
  <div
    :id="tabId + '-tab'"
    class="tab-pane"
    role="tabpanel"
    :aria-labelledby="tabLabel"
  >
    <div class="grid grid-cols-12 gap-6">
      <div class="intro-y box col-span-12 lg:col-span-12">
        <div
          class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
        >
          <h2 class="font-medium text-base mr-auto">{{ title }}</h2>
          <slot name="tools"></slot>
        </div>
        <main>
          <slot></slot>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TabContent",
  props: {
    tabId: {
      default: "",
      type: String,
    },
    tabLabel: {
      default: "",
      type: String,
    },
    title: {
      default: "",
      type: String,
    },
  },
};
</script>

<style scoped></style>
