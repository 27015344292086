<template>
  <div class="relative">
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">
        <router-link :to="{ name: 'formathonor' }">
          <ArrowLeftCircleIcon></ArrowLeftCircleIcon>
        </router-link>
        {{ id ? "Edit" : "Tambah" }} Honorarium
      </h2>
    </div>

    <div class="intro-y box p-5 mt-5">
      <AlertSuccess v-if="alertSuccess" :message="messageAlert" />

      <div class="grid sm:grid-cols-3 grid-cols-1 gap-2">
        <div class="mb-2">
          <label class="form-label">Tahun Anggaran</label>
          <select v-model="thnanggaran" class="form-select">
            <option
              v-for="(tahun, index) in listTahunAnggaran"
              :key="index"
              :value="tahun"
            >
              {{ tahun }}
            </option>
          </select>
          <span
            v-if="messageValidator?.tahun && messageValidator?.tahun.length > 0"
            class="text-red-600"
            >{{ messageValidator?.tahun[0] }}</span
          >
        </div>
        <div class="mb-2">
          <label class="form-label">
            <input
              v-model="chekWaktu"
              type="checkbox"
              @click="
                () => {
                  !chekWaktu;
                }
              "
            />
            Waktu kuliah</label
          >
          <select v-model="waktu" class="form-select" :disabled="!chekWaktu">
            <option
              v-for="(time, index) in listwaktu"
              :key="index"
              :value="time.kode"
            >
              {{ time.nama }}
            </option>
          </select>
          <span
            v-if="messageValidator?.waktu && messageValidator?.waktu.length > 0"
            class="text-red-600"
            >{{ messageValidator?.waktu[0] }}</span
          >
        </div>
        <div class="mb-2">
          <label class="form-label">
            <input
              v-model="chekJab"
              type="checkbox"
              @click="
                () => {
                  !chekJab;
                }
              "
            />
            Jabatan Fungsional</label
          >
          <select v-model="jabfung" class="form-select" :disabled="!chekJab">
            <option
              v-for="item in listJabfung"
              :key="item.id"
              :value="item.value"
              :selected="item.selected"
            >
              {{ item.text }}
            </option>
          </select>
          <span
            v-if="
              messageValidator?.jabfung && messageValidator?.jabfung.length > 0
            "
            class="text-red-600"
            >{{ messageValidator?.jabfung[0] }}</span
          >
        </div>

        <div class="mb-2">
          <label class="form-label">Kelompok</label>
          <TailSelect
            v-model="kelompok"
            :options="{
              search: true,
              classNames: 'w-full',
              deselect: false,
            }"
          >
            <option
              v-for="item in listKelompok"
              :key="item.id"
              :value="item.value"
              :selected="item.selected"
            >
              {{ item.text }}
            </option>
          </TailSelect>
          <span
            v-if="
              messageValidator?.kelompok &&
              messageValidator?.kelompok.length > 0
            "
            class="text-red-600"
            >{{ messageValidator?.kelompok[0] }}</span
          >
        </div>

        <div class="mb-2">
          <label class="form-label">Nominal</label>
          <input
            v-model="nominal"
            type="number"
            class="form-control"
            @keyup="formatRupiah(nominal)"
          />
          <span
            v-if="
              messageValidator?.nominal && messageValidator?.nominal.length > 0
            "
            class="text-red-600"
            >{{ messageValidator?.nominal[0] }}</span
          >
          <span v-else class="text-green-800 font-medium">
            {{ rupiahNominal }}
          </span>
        </div>
      </div>

      <div class="my-3">
        <button class="btn btn-primary w-full" @click="simpanHonor">
          SIMPAN
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { master, keuangan } from "@/services/models";
import { HTTPKEU } from "../../../services/http";
import AlertSuccess from "../../../components/alerts/AlertSuccess.vue";
import router from "@/router";

export default defineComponent({
  components: {
    AlertSuccess,
  },
  setup() {
    const route = useRoute();
    const id = route.params.id; // read parameter id (it is reactive)
    const store = useStore();
    return {
      store,
      id,
    };
  },
  data() {
    return {
      chekJab: false,
      jabfung: "",
      thnanggaran: new Date().getFullYear(),
      chekWaktu: false,
      waktu: "P",
      kelompok: "",
      nominal: 0,
      rupiahNominal: "",
      listJabfung: [],
      listTahunAnggaran: [],
      honorDetail: [],
      listKelompok: [],
      listwaktu: [
        { kode: "P", nama: "Pagi" },
        { kode: "S", nama: "Sore" },
        { kode: "K", nama: "Kelas Karyawan" },
      ],
      alertSuccess: false,
      messageAlert: "",
      messageValidator: [],
    };
  },
  watch: {
    id: {
      handler: function () {
        this.getHonor();
      },
      immediate: true,
    },
  },
  mounted() {
    this.getJabfung();
    this.tahunlist();
    this.getKelompokHonor();
  },
  methods: {
    formatRupiah(nominal) {
      if (nominal) {
        let rupiahLocal = Intl.NumberFormat("id-ID");
        this.rupiahNominal = "Rp." + rupiahLocal.format(nominal);
      } else {
        this.rupiahNominal = "";
      }
    },
    tahunlist() {
      let nowYear = new Date().getFullYear();
      // eslint-disable-next-line for-direction
      for (let i = nowYear + 1; i > nowYear - 8; i--) {
        this.listTahunAnggaran.push(i);
      }
    },
    getHonor() {
      if (this.id) {
        keuangan.Honorarium.getData(this.id).then((res) => {
          if (res.data.code === 200) {
            let detail = res.data.datas;
            this.honorDetail = detail;
            this.thnanggaran = detail.tahun_anggaran;
            this.chekJab = detail.id_jabfung ? true : false;
            this.jabfung = detail.id_jabfung;
            this.chekWaktu = detail.kelompok_kelas ? true : false;
            this.waktu = detail.kelompok_kelas;
            this.kelompok = detail.id_kelompok_honor.toString();
            this.nominal = detail.nominal;
          }
        });
      }
    },
    getJabfung() {
      this.listJabfung = [];
      let opt = {
        id: 0,
        value: "",
        text: "Pilih Jenis Beasiswa",
        selected: true,
      };
      this.listJabfung.push(opt);
      master.Jabfung.getCustomPath("listkelompok/Dosen").then((res) => {
        res.data.datas.forEach((item) => {
          const opt = {
            id: item.id_jabfung,
            value: item.id_jabfung,
            text: `POIN : ${item.angka_kredit} | ${item.nm_jabfung}`,
            selected: false,
          };
          this.listJabfung.push(opt);
        });
      });
    },
    getKelompokHonor() {
      this.listKelompok = [];
      let opt = {
        id: 0,
        value: "",
        text: "Pilih Kelompok Honor",
        selected: true,
      };
      this.listKelompok.push(opt);
      HTTPKEU("list/kelompok-honor").then((res) => {
        console.log(res.data);

        res.data.forEach((item) => {
          const opt = {
            id: item.id,
            value: item.id,
            text: item.ket_kelompok_honor,
            selected: false,
          };
          this.listKelompok.push(opt);
        });
      });
    },
    simpanHonor() {
      if (this.id) {
        let val = {
          thnanggaran: this.thnanggaran,
          chekWaktu: this.chekWaktu,
          waktu: this.waktu,
          chekJab: this.chekJab,
          jabfung: this.jabfung,
          kelompok: this.kelompok,
          nominal: this.nominal,
        };
        keuangan.Honorarium.updateData(this.id, val).then((res) => {
          if (res.data.code === 200) {
            this.alertSuccess = true;
            this.messageAlert = res.data.message;
          } else {
            this.messageValidator = res.data.message;
          }
        });
      } else {
        let val = {
          thnanggaran: this.thnanggaran,
          chekWaktu: this.chekWaktu,
          waktu: this.waktu,
          chekJab: this.chekJab,
          jabfung: this.jabfung,
          kelompok: this.kelompok,
          nominal: this.nominal,
        };

        this.alertSuccess = false;
        keuangan.Honorarium.insertData(val).then((res) => {
          if (res.data.code === 200) {
            this.alertSuccess = true;
            this.messageAlert = res.data.message;
          } else {
            this.messageValidator = res.data.message;
          }
        });
      }
    },
  },
});
</script>
