<template>
  <div>
    <!-- BEGIN: HTML Form Generator -->
    <div class="intro-y">
      <SavingStatus :is-saving="savingStatus" />
      <div v-if="!loading" class="intro-y col-span-12 lg:col-span-6">
        <form
          v-if="pesertadidik !== null && hasFeatures('edit:mahasiswa')"
          @submit.prevent="submitPesertaDidik"
        >
          <div class="grid grid-cols-12 gap-6">
            <div class="intro-y col-span-12 lg:col-span-6">
              <GenerateForm
                :inputs="schema1"
                :model="pesertadidik"
                :options-data="listOptions"
              />
            </div>
            <div class="intro-y col-span-12 lg:col-span-6">
              <GenerateForm
                :inputs="schema2"
                :model="pesertadidik"
                :options-data="listOptions"
              />
            </div>
          </div>
          <div class="d-flex justify-content-end mt-3 pr-4">
            <button type="submit" class="btn btn-primary w-24">
              {{
                savingButton
                  ? "Saving..."
                  : id?.length > 0
                  ? "Update"
                  : "Submit"
              }}
            </button>
            <button
              id="btn-reset-form"
              type="reset"
              class="btn btn-default w-24 ml-1"
            >
              Reset
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import pdFormSchema1 from "../../../forms/pd-form-schema1";
import pdFormSchema2 from "../../../forms/pd-form-schema2";
import GenerateForm from "../../../components/form/GenerateForm";
import { ref } from "@/services/models";
import SavingStatus from "../../../components/alerts/SavingStatus";

export default defineComponent({
  components: {
    SavingStatus,
    GenerateForm,
  },
  props: {
    schema1: {
      type: Object,
      default: pdFormSchema1,
    },
    schema2: {
      type: Object,
      default: pdFormSchema2,
    },
    pesertadidik: {
      type: Object,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      listOptions: {
        id_jenjang_pendidikan_ayah: [],
        id_pekerjaan_ayah: [],
        id_penghasilan_ayah: [],
        // id_kebutuhan_khusus_ayah: [],
        id_jenjang_pendidikan_ibu: [],
        id_pekerjaan_ibu: [],
        id_penghasilan_ibu: [],
        // id_kebutuhan_khusus_ibu: [],
        id_agama: [],
        id_wil: [],
        // id_kk: [],
        jk: [],
        a_terima_kps: [],
        id_jns_tinggal: [],
        id_alat_transport: [],
      },
      isSaving: false,
      btnSave: false,
      loading: true,
    };
  },
  computed: {
    savingStatus() {
      return this.isSaving;
    },
    savingButton() {
      return this.btnSave;
    },
    isSuccess() {
      return this.$store.state.main.isSuccess;
    },
    loadingStatus() {
      return this.$store.state.main.loadingStatus;
    },
  },
  watch: {
    pesertadidik: {
      handler: function (newData) {
        if (newData?.id_pd !== undefined) {
          this.loading = false;
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.fetchJp();
    this.fetchAgama();
    this.fetchTransport();
    this.fetchPekerjaan();
    this.fetchPenghasilan();
    this.listJk();
    this.listTerimaKPS();
    this.fetchWilayah();
    this.fetchJenisTinggal();
  },
  methods: {
    async fetchJp() {
      let jpAyah = {
        id: 0,
        value: "",
        text: "Pilih Jenjang Pendidikan Ayah",
        selected: true,
      };
      let jpIbu = {
        id: 0,
        value: "",
        text: "Pilih Jenjang Pendidikan Ibu",
        selected: true,
      };
      this.listOptions.id_jenjang_pendidikan_ayah.push(jpAyah);
      this.listOptions.id_jenjang_pendidikan_ibu.push(jpIbu);
      let that = this;
      ref.jenjPend.getAll().then((result) => {
        if (result.status === 200) {
          result.data.data.forEach((item) => {
            let newAyah = {
              id: item.id,
              value: item.id_jenj_didik,
              text: item.nm_jenj_didik,
              selected:
                that.pesertadidik?.id_jenjang_pendidikan_ayah ===
                item.id_jenj_didik,
            };
            let newIbu = {
              id: item.id,
              value: item.id_jenj_didik,
              text: item.nm_jenj_didik,
              selected:
                that.pesertadidik?.id_jenjang_pendidikan_ibu ===
                item.id_jenj_didik,
            };
            that.listOptions.id_jenjang_pendidikan_ayah.push(newAyah);
            that.listOptions.id_jenjang_pendidikan_ibu.push(newIbu);
          });
        }
      });
    },
    async fetchAgama() {
      let agama = {
        id: 0,
        value: "",
        text: "Pilih Agama",
        selected: true,
      };
      this.listOptions.id_agama.push(agama);
      let that = this;
      ref.agama.getAll().then((result) => {
        if (result.status === 200) {
          result.data.data.forEach((item) => {
            let newAgama = {
              id: item.id,
              value: item.id,
              text: item.name,
              selected: that.pesertadidik?.id_agama === item.id,
            };
            that.listOptions.id_agama.push(newAgama);
          });
        }
      });
    },
    async fetchTransport() {
      let trans = {
        id: 0,
        value: "",
        text: "Pilih Alat Transport",
        selected: true,
      };
      this.listOptions.id_alat_transport.push(trans);
      let that = this;
      ref.transport.getAll().then((result) => {
        if (result.status === 200) {
          result.data.data.forEach((item) => {
            let newTrans = {
              id: item.id,
              value: item.id_alat_transport,
              text: item.nm_alat_transport,
              selected:
                that.pesertadidik?.id_alat_transport === item.id_alat_transport,
            };
            that.listOptions.id_alat_transport.push(newTrans);
          });
        }
      });
    },
    async fetchWilayah() {
      let wilayah = {
        id: 0,
        value: "",
        text: "Pilih Wilayah",
        selected: true,
      };
      this.listOptions.id_wil.push(wilayah);
      let that = this;
      ref.wilayah.getAll().then((result) => {
        if (result.status === 200) {
          result.data.data.forEach((item) => {
            let newWilayah = {
              id: item.id_wil,
              value: item.id_wil,
              text: item.nm_wil,
              selected: that.pesertadidik?.id_wil === item.id_wil,
            };
            that.listOptions.id_wil.push(newWilayah);
          });
        }
      });
    },
    async fetchPekerjaan() {
      let pekerjaanAyah = {
        id: 0,
        value: "",
        text: "Pilih Pekerjaan Ayah",
        selected: true,
      };
      let pekerjaanIbu = {
        id: 0,
        value: "",
        text: "Pilih Pekerjaan Ibu",
        selected: true,
      };
      this.listOptions.id_pekerjaan_ayah.push(pekerjaanAyah);
      this.listOptions.id_pekerjaan_ibu.push(pekerjaanIbu);
      let that = this;
      ref.pekerjaan.getAll().then((result) => {
        if (result.status === 200) {
          result.data.data.forEach((item) => {
            let newPekerjaanAyah = {
              id: item.id,
              value: item.id_pekerjaan,
              text: item.nm_pekerjaan,
              selected:
                that.pesertadidik?.id_pekerjaan_ayah === item.id_pekerjaan,
            };
            let newPekerjaanIbu = {
              id: item.id,
              value: item.id_pekerjaan,
              text: item.nm_pekerjaan,
              selected:
                that.pesertadidik?.id_pekerjaan_ibu === item.id_pekerjaan,
            };
            that.listOptions.id_pekerjaan_ayah.push(newPekerjaanAyah);
            that.listOptions.id_pekerjaan_ibu.push(newPekerjaanIbu);
          });
        }
      });
    },
    async fetchPenghasilan() {
      let penghasilanAyah = {
        id: 0,
        value: "",
        text: "Pilih Penghasilan Ayah",
        selected: true,
      };
      let penghasilanIbu = {
        id: 0,
        value: "",
        text: "Pilih Penghasilan Ibu",
        selected: true,
      };
      this.listOptions.id_penghasilan_ayah.push(penghasilanAyah);
      this.listOptions.id_penghasilan_ibu.push(penghasilanIbu);
      let that = this;
      ref.penghasilan.getAll().then((result) => {
        if (result.status === 200) {
          result.data.data.forEach((item) => {
            let newPenghasilanAyah = {
              id: item.id,
              value: item.id_penghasilan,
              text: item.nm_penghasilan,
              selected:
                that.pesertadidik?.id_penghasilan_ayah === item.id_penghasilan,
            };
            let newPenghasilanIbu = {
              id: item.id,
              value: item.id_penghasilan,
              text: item.nm_penghasilan,
              selected:
                that.pesertadidik?.id_pekerjaan_ibu === item.id_penghasilan,
            };
            that.listOptions.id_penghasilan_ayah.push(newPenghasilanAyah);
            that.listOptions.id_penghasilan_ibu.push(newPenghasilanIbu);
          });
        }
      });
    },
    async fetchKk() {
      let Kk = {
        id: 0,
        value: "",
        text: "Pilih Kebutuhan Khusus",
        selected: true,
      };
      this.listOptions.id_kk.push(Kk);
      this.listOptions.id_kebutuhan_khusus_ayah.push(Kk);
      this.listOptions.id_kebutuhan_khusus_ibu.push(Kk);
      let that = this;
      ref.kebutuhankhusus.getAll().then((result) => {
        if (result.status === 200) {
          result.data.data.forEach((item) => {
            let newKebutuhanMhs = {
              id: item.id,
              value: item.id_kk,
              text: item.nm_kk,
              selected: that.pesertadidik?.id_kk === item.id_kk,
            };
            let newKebutuhanAyah = {
              id: item.id,
              value: item.id_kk,
              text: item.nm_kk,
              selected:
                that.pesertadidik?.id_kebutuhan_khusus_ayah === item.id_kk,
            };
            let newKebutuhanIbu = {
              id: item.id,
              value: item.id_kk,
              text: item.nm_kk,
              selected:
                that.pesertadidik?.id_kebutuhan_khusus_ibu === item.id_kk,
            };
            that.listOptions.id_kk.push(newKebutuhanMhs);
            that.listOptions.id_kebutuhan_khusus_ayah.push(newKebutuhanAyah);
            that.listOptions.id_kebutuhan_khusus_ibu.push(newKebutuhanIbu);
          });
        }
      });
    },
    async fetchJenisTinggal() {
      let tinggal = {
        id: 0,
        value: "",
        text: "Pilih Jenis Tinggal",
        selected: true,
      };
      this.listOptions.id_jns_tinggal.push(tinggal);
      let that = this;
      ref.jenistinggal.getAll().then((result) => {
        if (result.status === 200) {
          result.data.data.forEach((item) => {
            let newJenisTinggal = {
              id: item.id,
              value: item.id_jns_tinggal,
              text: item.nm_jns_tinggal,
              selected:
                that.pesertadidik?.id_jns_tinggal === item.id_jns_tinggal,
            };
            that.listOptions.id_jns_tinggal.push(newJenisTinggal);
          });
        }
      });
    },
    listJk() {
      let jkL = {
        id: "L",
        value: "L",
        text: "Laki-Laki",
      };
      let jkP = {
        id: "P",
        value: "P",
        text: "Perempuan",
      };
      this.listOptions.jk.push(jkL);
      this.listOptions.jk.push(jkP);
    },
    listTerimaKPS() {
      let tidak = {
        id: "0",
        value: "0",
        text: "Tidak",
      };
      let ya = {
        id: "1",
        value: "1",
        text: "Ya",
      };
      this.listOptions.a_terima_kps.push(tidak);
      this.listOptions.a_terima_kps.push(ya);
    },
    submitPesertaDidik() {
      this.btnSave = true;
      this.isSaving = true;
      if (this.id === undefined) {
        this.$store.dispatch("PesertaDidik/save", {
          payload: this.pesertadidik,
          initialValues: this.initialValues,
        });
      } else {
        this.$store.dispatch("PesertaDidik/update", {
          id: this.pesertadidik.id,
          payload: this.pesertadidik,
        });
      }
      this.btnSave = false;
    },
  },
});
</script>

<style></style>
