<template>
  <div>
    <div class="text-gray-600 text-sm font-medium">
      {{ pesertadidik?.reg_pd.nipd }}
    </div>
    <div class="text-gray-500 capitalize">
      {{ pesertadidik?.reg_pd.jenis_keluar?.ket_keluar }}
    </div>
    <div class="text-gray-600">
      {{ pesertadidik?.reg_pd.sms.jenjang_pendidikan.nm_jenj_didik }}
      {{ pesertadidik?.reg_pd.sms.nm_lemb }} ({{
        pesertadidik?.reg_pd.mulai_smt
      }})
    </div>
    <div class="text-gray-800 text-xs mb-2">
      {{ pesertadidik?.reg_pd.satuan_pendidikan.nm_lemb }}
    </div>
  </div>
</template>

<script>
export default {
  name: "SubInfoPD",
  props: {
    pesertadidik: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style scoped></style>
