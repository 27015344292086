<template>
  <div class="relative">
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">
        <router-link :to="{ name: 'bank' }">
          <ArrowLeftCircleIcon></ArrowLeftCircleIcon>
        </router-link>
        {{ id ? "Edit" : "Tambah" }} Bank
      </h2>
    </div>

    <div class="intro-y box p-5 mt-5">
      <div class="grid sm:grid-cols-3 grid-cols-1 gap-2">
        <div class="mb-2">
          <label class="form-label">Kode Bank</label>
          <input
            v-model="kode"
            type="text"
            class="form-control"
            :disabled="id"
            maxlength="5"
          />
          <span
            v-if="messageValidator?.kode && messageValidator?.kode.length > 0"
            class="text-red-600"
            >{{ messageValidator?.kode[0] }}</span
          >
        </div>

        <div class="mb-2">
          <label class="form-label">Nama Bank</label>
          <input v-model="Keterangan" type="text" class="form-control" />
          <span
            v-if="
              messageValidator?.Keterangan &&
              messageValidator?.Keterangan.length > 0
            "
            class="text-red-600"
            >{{ messageValidator?.Keterangan[0] }}</span
          >
        </div>

        <div class="mb-2">
          <label class="form-label">Company Code</label>
          <input v-model="company_code" type="number" class="form-control" />
          <span
            v-if="
              messageValidator?.company_code &&
              messageValidator?.company_code.length > 0
            "
            class="text-red-600"
            >{{ messageValidator?.company_code[0] }}</span
          >
        </div>
      </div>

      <div class="my-3">
        <button class="btn btn-primary w-full" @click="simpanBank">
          SIMPAN
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { keuangan } from "@/services/models";
import router from "@/router";

export default defineComponent({
  setup() {
    const route = useRoute();
    const id = route.params.id; // read parameter id (it is reactive)
    const store = useStore();
    return {
      store,
      id,
    };
  },
  data() {
    return {
      kode: "",
      Keterangan: "",
      company_code: "",
      bankDetail: [],
      messageValidator: [],
    };
  },
  watch: {
    id: {
      handler: function () {
        this.getBank();
      },
      immediate: true,
    },
  },
  methods: {
    getBank() {
      keuangan.Bank.getData(this.id).then((res) => {
        if (res.data.code === 200) {
          let detail = res.data.datas;
          this.bankDetail = detail;
          this.kode = detail.kode;
          this.Keterangan = detail.Keterangan;
          this.company_code = detail.company_code;
        }
      });
    },
    simpanBank() {
      if (this.id) {
        let val = {
          Keterangan: this.Keterangan,
          company_code: this.company_code,
        };
        keuangan.Bank.updateData(this.id, val).then((res) => {
          if (res.data.code === 200) {
            router.push({
              name: "bank",
            });
          } else {
            this.messageValidator = res.data.message;
          }
        });
      } else {
        let val = {
          kode: this.kode,
          Keterangan: this.Keterangan,
          company_code: this.company_code,
        };

        keuangan.Bank.insertData(val).then((res) => {
          if (res.data.code === 200) {
            router.push({
              name: "bank",
            });
          } else {
            this.messageValidator = res.data.message;
          }
        });
      }
    },
  },
});
</script>
