<template>
  <div v-if="infoMhs?.nm_pd">
    <div class="flex items-center mb-4">
      <label class="form-label mr-2 mt-2">Pilih Semester</label>
      <select
        v-model="semester"
        class="form-control w-auto focus:outline-none focus:ring-0"
      >
        <option
          v-for="smtr in listSemester"
          :key="smtr.id"
          :value="smtr.value"
          :selected="smtr.selected"
        >
          {{ smtr.text }}
        </option>
      </select>
    </div>
    <div class="overflow-x-auto">
      <div v-if="detailMahasiswa !== null" class="intro-y box mt-3">
        <NilaiSemester
          :semester="semester"
          :kodemahasiswa="kodeMahasiswa"
          :detailmahasiswa="detailMahasiswa"
          :isaction="isaction"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { master } from "@/services/models";
import NilaiSemester from "@/components/table-and-list/NilaiSemester";
export default {
  name: "NilaiSemesterMahasiswa",
  components: {
    NilaiSemester,
  },
  props: {
    infoMhs: {
      type: Object,
      default: null,
    },
    smt: {
      type: String,
      default: "",
    },
    kodeMahasiswa: {
      type: String,
      default: "",
    },
    detailMahasiswa: {
      type: Object,
      default: null,
    },
    isaction: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      semester: null,
      listSemester: [],
    };
  },
  computed: {
    currentSemester() {
      return this.$store.state.main.currentSemester;
    },
  },
  watch: {
    smt: {
      handler: function (newval) {
        this.semester = newval;
      },
      immediate: true,
    },
    kodeMahasiswa: {
      handler: function () {
        this.fetchSemester();
      },
      immediate: true,
    },
    semester: {
      handler: function () {
        this.$emit("changeSemester", this.semester);
      },
      immediate: true,
    },
  },
  mounted() {
    //this.fetchSemester();
  },
  methods: {
    fetchSemester() {
      let that = this;
      this.listSemester = [];
      this.listSemester.push({
        id: "0",
        value: "0",
        text: "Pilih Semester",
        selected: false,
      });
      let path =
        this.detailMahasiswa?.id_reg_pd === undefined
          ? "smt_kuliah_mhs"
          : "smt_kuliah_mhs/" + this.detailMahasiswa?.id_reg_pd;
      master.SemesterAkad.getCustomPath(path).then((result) => {
        if (result.status === 200) {
          result.data.data.forEach((item) => {
            let newoption = {
              id: item.id_smt,
              value: item.id_smt,
              text: item.nm_smt,
              selected: that.semester.toString() === item.id_smt,
            };

            if (newoption.selected === true)
              that.semester = item.id_smt.toString();
            that.listSemester.push(newoption);
          });
        }
      });
    },
  },
};
</script>

<style scoped></style>
