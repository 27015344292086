<template>
  <div>
    <div v-if="isSaving === true && isSuccess === true">
      <AlertSuccess :message="responseMessage" />
    </div>
    <div v-else-if="isSaving === true && isSuccess === false">
      <AlertFailed :message="responseMessage" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import AlertFailed from "../alerts/AlertFailed";
import AlertSuccess from "../alerts/AlertSuccess";
export default defineComponent({
  components: { AlertFailed, AlertSuccess },
  props: {
    isSaving: {
      type: Boolean,
      default: null,
    },
  },
  computed: {
    isSuccess() {
      return this.$store.state.main.isSuccess;
    },
    responseMessage() {
      return this.$store.state.main.responseMessage;
    },
  },
});
</script>
