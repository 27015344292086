export default {
  fields: [
    {
      type: "input",
      inputType: "text",
      label: "Nama Peserta Didik",
      model: "nm_pd",
      placeholder: "Nama Peserta Didik",
      rule: {
        required: true,
      },
    },
    {
      type: "radio",
      radioinline: true,
      label: "Jenis Kelamin",
      model: "jk",
      placeholder: "Jenis Kelamin",
    },
    {
      type: "textarea",
      label: "Jalan",
      model: "jln",
      placeholder: "Jalan",
      rule: {
        required: true,
      },
    },
    {
      type: "input",
      inputType: "text",
      label: "RT",
      model: "rt",
      placeholder: "RT",
    },
    {
      type: "input",
      inputType: "text",
      label: "RW",
      model: "rw",
      placeholder: "RW",
    },
    {
      type: "input",
      inputType: "text",
      label: "Nama Dusun",
      model: "nm_dsn",
      placeholder: "Nama Dusun",
    },
    {
      type: "input",
      inputType: "text",
      label: "Desa/Kelurahan",
      model: "ds_kel",
      placeholder: "Desa/Kelurahan",
    },
    {
      type: "input",
      inputType: "text",
      label: "Kode Pos",
      model: "kode_pos",
      placeholder: "Kode Pos",
    },
    {
      type: "input",
      inputType: "text",
      label: "NISN",
      model: "nisn",
      placeholder: "NISN",
    },
    {
      type: "input",
      inputType: "text",
      label: "NIK",
      model: "nik",
      placeholder: "NIK",
    },
    {
      type: "input",
      inputType: "text",
      label: "Tempat Lahir",
      model: "tmpt_lahir",
      placeholder: "Tempat Lahir",
    },
    {
      type: "dateTimePicker",
      inputType: "date",
      label: "Tanggal Lahir",
      model: "tgl_lahir",
      placeholder: "Tanggal Lahir",
      format: "YYYY.MM.DD",
      dateTimePickerOptions: {
        format: "YYYY-MM-DD",
      },
    },
    {
      type: "input",
      inputType: "text",
      label: "Nama Ayah",
      model: "nm_ayah",
      placeholder: "Nama Ayah",
    },
    {
      type: "dateTimePicker",
      inputType: "date",
      label: "Tanggal Lahir Ayah",
      model: "tgl_lahir_ayah",
      placeholder: "Tanggal Lahir Ayah",
      format: "YYYY.MM.DD",
      dateTimePickerOptions: {
        format: "YYYY-MM-DD",
      },
    },
    {
      type: "input",
      inputType: "text",
      label: "NIK Ayah",
      model: "nik_ayah",
      placeholder: "NIK Ayah",
    },
    {
      type: "select",
      inputType: "text",
      label: "Jenjang Pendidikan Ayah",
      model: "id_jenjang_pendidikan_ayah",
      placeholder: "Pilih Jenjang Pendidikan Ayah",
    },
    {
      type: "select",
      inputType: "text",
      label: "Pekerjaan Ayah",
      model: "id_pekerjaan_ayah",
      placeholder: "Pilih Pekerjaan Ayah",
    },
    {
      type: "select",
      inputType: "text",
      label: "Penghasilan Ayah",
      model: "id_penghasilan_ayah",
      placeholder: "Pilih Penghasilan Ayah",
    },
    // {
    //   type: "select",
    //   inputType: "text",
    //   label: "Kebutuhan Khusus Ayah",
    //   model: "id_kebutuhan_khusus_ayah",
    //   placeholder: "Pilih Kebutuhan Khusus Ayah"
    // },
    /*{
      type: "input",
      inputType: "text",
      label: "Kebutuhan Khusus Ayah",
      model: "id_kebutuhan_khusus_ayah",
      placeholder: "Pilih Kebutuhan Khusus Ayah",
    },*/
  ],
};
