<template>
  <div>
    <table class="table">
      <thead>
        <tr class="bg-gray-700 dark:bg-dark-1 text-white">
          <th class="whitespace-nowrap">#</th>
          <th class="whitespace-nowrap">NPM</th>
          <th class="whitespace-nowrap">Program Studi</th>
          <th class="whitespace-nowrap">Jenis Daftar</th>
          <th class="whitespace-nowrap">Angkatan</th>
          <th class="whitespace-nowrap">Tanggal Masuk</th>
          <th class="whitespace-nowrap">AKM</th>
          <th class="whitespace-nowrap">Pindahan</th>
          <th class="whitespace-nowrap">Status Keluar</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(pd, key) in regPD"
          :key="pd.id_reg_pd"
          class="hover:bg-gray-200"
        >
          <td class="border-b dark:border-dark-5">{{ ++key }}</td>
          <td class="border-b dark:border-dark-5">{{ pd.nipd }}</td>
          <td class="border-b dark:border-dark-5">
            {{ pd.sms.nm_lemb }} {{ pd.sms.jenjang_pendidikan.nm_jenj_didik }}
            <div class="text-gray-600 text-sm font-medium">
              {{ pd.satuan_pendidikan.nm_lemb }}
            </div>
          </td>
          <td class="border-b dark:border-dark-5">
            {{ pd.jenis_pendaftaran.nm_jns_daftar }}
          </td>
          <td class="border-b dark:border-dark-5">
            {{ pd.mulai_smt }}
          </td>
          <td class="border-b dark:border-dark-5">
            {{ pd.tgl_masuk_sp }}
          </td>

          <td class="border-b dark:border-dark-5">
            <button class="btn btn-sm btn-success">
              <eye-icon class="w-4 h-4 mr-2"></eye-icon>
              Lihat
            </button>
          </td>
          <td class="border-b dark:border-dark-5">
            <div v-if="pd.nm_prodi_asal !== null">
              {{ pd.nm_prodi_asal }}
              <div class="text-gray-600 text-sm font-medium">
                {{ pd.nm_pt_asal }}
              </div>
              Sks Diakui : {{ parseInt(pd.sks_diakui) }}
            </div>
            <div v-else>
              <div
                class="bg-theme-8 text-theme-1 rounded p-2 w-auto text-center"
              >
                Bukan
              </div>
            </div>
          </td>
          <td class="border-b dark:border-dark-5">
            <div v-if="pd.id_jns_keluar !== null">
              <button
                class="btn btn-sm btn-primary"
                @click="setDetailKeluar(pd.jenis_keluar, pd)"
              >
                <external-link-icon class="w-4 h-4 mr-2"></external-link-icon>
                {{ pd.jenis_keluar?.ket_keluar }}
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div v-if="keluar != null" class="mt-5">
      <h4 class="font-medium mr-auto">Detail Peserta Didik</h4>
      <div class="mt-2">
        <div class="flex flex-col sm:flex-row">
          <div class="mr-10 w-40">
            <div class="text-gray-600 mt-1">Status Keluar</div>
          </div>
          <div class="flex">
            <div class="text-left">
              <div class="font-medium mt-1">
                {{ detailKeluar.jenis_keluar.ket_keluar }}
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col sm:flex-row">
          <div class="mr-10 w-40">
            <div class="text-gray-600 mt-1">Tanggal Keluar</div>
          </div>
          <div class="flex">
            <div class="text-left">
              <div class="font-medium mt-1">
                {{ detailKeluar.tgl_keluar }}
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-col sm:flex-row">
          <div class="mr-10 w-40">
            <div class="text-gray-600 mt-1">Jalur Skripsi</div>
          </div>
          <div class="flex">
            <div class="text-left">
              <div class="bg-theme-18 text-theme-9 rounded px-2 mt-1">
                {{
                  parseInt(detailKeluar.jalur_skripsi) === 1 ? "YA" : "TIDAK"
                }}
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-col sm:flex-row">
          <div class="mr-10 w-40">
            <div class="text-gray-600 mt-1">Judul Skripsi</div>
          </div>
          <div class="flex">
            <div class="text-left">
              <div class="font-medium mt-1">
                {{ detailKeluar.judul_skripsi }}
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col sm:flex-row">
          <div class="mr-10 w-40">
            <div class="text-gray-600 mt-1">SK Yudisium</div>
          </div>
          <div class="flex">
            <div class="text-left">
              <div class="font-medium mt-1">
                {{ detailKeluar.sk_yudisium }}
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col sm:flex-row">
          <div class="mr-10 w-40">
            <div class="text-gray-600 mt-1">Smt Yudisium</div>
          </div>
          <div class="flex">
            <div class="text-left">
              <div class="font-medium mt-1">
                {{ detailKeluar.smt_yudisium }}
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col sm:flex-row">
          <div class="mr-10 w-40">
            <div class="text-gray-600 mt-1">No. Seri Ijazah</div>
          </div>
          <div class="flex">
            <div class="text-left">
              <div class="font-medium mt-1">
                {{ detailKeluar.no_seri_ijazah }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DataRegPD",
  props: {
    regPD: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      detailKeluar: null,
      keluar: null,
    };
  },
  watch: {
    regPD: {
      handler: function () {},
      immediate: true,
    },
  },
  methods: {
    setDetailKeluar(keluar, detail) {
      this.detailKeluar = detail;
      this.keluar = keluar;
    },
  },
};
</script>

<style scoped></style>
