<template>
  <div class="relative">
    <div v-if="loading" class="block-loading">
      <LoadingIcon icon="three-dots" class="w-8 h-8" />
      <div class="text-center text-xs mt-2">Loading data... Please Wait...</div>
    </div>
    <div class="preview p-3">
      <div class="overflow-x-auto">
        <table class="table">
          <thead>
            <tr class="text-center">
              <th
                class="
                  border border-b-2
                  dark:border-indigo-600
                  whitespace-nowrap
                  text-xs
                  w-40
                "
              >
                Hari / Jam
              </th>
              <th
                class="
                  border border-b-2
                  dark:border-indigo-600
                  whitespace-nowrap
                  w-48
                  max-w-xs
                "
              >
                Senin
              </th>
              <th
                class="
                  border border-b-2
                  dark:border-indigo-600
                  whitespace-nowrap
                  w-48
                  max-w-xs
                "
              >
                Selasa
              </th>
              <th
                class="
                  border border-b-2
                  dark:border-indigo-600
                  whitespace-nowrap
                  w-48
                  max-w-xs
                "
              >
                Rabu
              </th>
              <th
                class="
                  border border-b-2
                  dark:border-indigo-600
                  whitespace-nowrap
                  w-48
                  max-w-xs
                "
              >
                Kamis
              </th>
              <th
                class="
                  border border-b-2
                  dark:border-indigo-600
                  whitespace-nowrap
                  w-48
                  max-w-xs
                "
              >
                Jum'at
              </th>
              <th
                class="
                  border border-b-2
                  dark:border-indigo-600
                  whitespace-nowrap
                  w-48
                  max-w-xs
                "
              >
                Sabtu
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in listJam" :key="index">
              <th class="border dark:border-indigo-600 text-center">
                {{ item.jam }} - {{ item.selesai }}
              </th>
              <td
                v-for="i in 6"
                :key="i"
                class="border dark:border-indigo-600 align-top"
              >
                <div v-for="(data, idx) in listDataJam" :key="idx">
                  <JadwalKuliahTableItem
                    v-if="data.kodeHari == i && item.id == data.id_jam"
                    :class="[
                      itemClass,
                      currentIdx != idx
                        ? data?.prodi?.fakultas?.kode_angka == '1'
                          ? 'bg-green-500'
                          : data.isOpenClass == 1
                          ? 'bg-purple-500'
                          : 'bg-blue-500'
                        : '',
                    ]"
                    :detailmk="data"
                    @click="setData(data, idx)"
                  />
                </div>
              </td>
            </tr>
            <tr v-if="listJam.length == 0">
              <td class="border text-center" colspan="7">Belum Ada Jadwal</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <SlideOverModal :id="'konfirmasi-mengajar'"></SlideOverModal>
  </div>
</template>

<script>
import SlideOverModal from "../../../layouts/main/SlideOverModal";
import JadwalKuliahTableItem from "./JadwalKuliahTableItem";
import { perkuliahan } from "@/services/models";
export default {
  name: "JadwalKuliahTable",
  components: {
    // JadwalKuliahTableItemShow,
    JadwalKuliahTableItem,
    SlideOverModal,
  },
  props: {
    datamk: {
      type: Object,
      default: () => ({}),
    },
    selecteddata: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      listJam: [],
      listDataJam: [],
      loading: false,
      itemfocus: [],
      currentIdx: null,
      currentData: null,
    };
  },
  computed: {
    itemClass: function () {
      let addclass = "";
      if (this.selecteddata) {
        let idx = this.currentIdx;
        if (idx != null) {
          this.itemfocus.forEach((i, j) => {
            if (idx == j) this.itemfocus[j] = true;
            else this.itemfocus[j] = false;
          });

          addclass = this.itemfocus[idx]
            ? "cursor-pointer bg-red-500"
            : "cursor-pointer";
        } else {
          addclass = "cursor-pointer";
        }
      }

      return addclass;
    },
  },
  watch: {
    datamk: function () {
      this.itemfocus = [];
      this.currentIdx = null;
      this.currentData = null;
      this.fetchJam();
    },
  },
  methods: {
    async fetchJam() {
      this.loading = true;
      perkuliahan.Jam.getAll().then((res) => {
        if (res.data.code === 200) {
          this.listJam = [];
          let dataJam = res.data.data;
          dataJam = dataJam.filter((item) => item.puasa === 0);
          let dataJamTmp = [];

          let n = 1;
          dataJam.forEach((element) => {
            const kodeJam = element.kodejam;
            let flag = true;
            this.datamk?.forEach((detailmk) => {
              detailmk?.waktu?.forEach((item) => {
                const kodeJamMK = item.kodejam;
                if (kodeJam === kodeJamMK) {
                  flag = false;
                  dataJamTmp.push({
                    adaMK: true,
                    code: n,
                    id_jam: element.id,
                    kodeJam: element.kodejam,
                    kodeHari: item.kodehari,
                    jam: element.jam,
                    selesai: element.selesai,
                    kelas: detailmk.kelas,
                    kodeMK: detailmk.kode_mk,
                    nama_mk: detailmk.nama_mk,
                    sks_mk: detailmk.sks_mk,
                    nm_dosen: detailmk.dosen,
                    ruang: item.ruangan.nm_ruang,
                    id_kls: detailmk.id_kelas,
                    prodi: detailmk?.prodi,
                    isOpenClass: detailmk?.isOpenClass,
                    idPertemuan: detailmk?.idPertemuan,
                  });
                } else {
                  if (flag) {
                    flag = false;
                    dataJamTmp.push({
                      adaMK: false,
                      code: n,
                      id_jam: element.id,
                      kodeJam: element.kodejam,
                      kodeHari: null,
                      jam: element.jam,
                      selesai: element.selesai,
                      kelas: null,
                      kodeMK: null,
                      nama_mk: null,
                      sks_mk: null,
                      nm_dosen: null,
                      ruang: null,
                      id_kls: null,
                      prodi: null,
                      isOpenClass: null,
                      idPertemuan: null,
                    });
                  }
                }
                n++;
              });
            });
          });
          const duplicateIds = dataJamTmp
            .map((e) => e["id_jam"])
            .map((e, i, final) => final.indexOf(e) !== i && i)
            .filter((obj) => dataJamTmp[obj])
            .map((e) => dataJamTmp[e]["id_jam"]);

          let duplicate = dataJamTmp
            .filter((obj) => duplicateIds.includes(obj.id_jam))
            .filter((item) => item.adaMK === false);

          dataJamTmp.forEach((item, index) => {
            duplicate.forEach((e) => {
              if (item.code === e.code) {
                dataJamTmp.splice(index, 1);
              }
            });
          });

          this.listJam = dataJam;
          this.listDataJam = dataJamTmp;
          this.loading = false;
        }
      });
    },
    setData(val, idx) {
      if (this.selecteddata) {
        this.currentIdx = idx;
        this.currentData = val;
        this.itemfocus[idx] = true;
        this.$emit("dataselect", val);
      }
    },
  },
};
</script>

<style scoped>
.block-loading {
  display: flex;
  position: absolute;
  background: #9e9e9e4f;
  height: 100%;
  width: 100%;
  z-index: 99;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 32px;
  flex-direction: column;
}
</style>
