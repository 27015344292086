<template>
  <div
    class="flex flex-col lg:flex-row border-b border-gray-200 dark:border-dark-5 pb-5 -mx-5"
  >
    <div
      class="flex flex-1 px-5 items-center justify-center lg:justify-start border-r border-gray-200 dark:border-dark-5"
    >
      <div
        class="w-20 h-20 sm:w-24 sm:h-24 flex-none lg:w-32 lg:h-32 image-fit relative"
      >
        <img
          alt="Icewall Tailwind HTML Admin Template"
          class="rounded-full"
          src="https://api.mdp.ac.id/public/assets/logo/logo-umdp.png"
        />
      </div>
      <div class="ml-5">
        <div
          class="w-24 sm:w-40 truncate sm:whitespace-normal font-medium text-sm"
        >
          {{ pesertadidik?.nm_pd }}
        </div>
        <div v-if="pesertadidik?.reg_pd">
          <SubInfoPD :pesertadidik="pesertadidik"></SubInfoPD>
        </div>
        <div v-else>
          <div v-for="regpd in pesertadidik?.reg_pds" :key="regpd.id_reg_pd">
            <SubInfoPD :pesertadidik="regpd"></SubInfoPD>
          </div>
        </div>

        <div v-if="dosenPA">
          <div
            class="w-full border-t border-gray-200 dark:border-dark-5 my-1"
          ></div>
          <div class="text-theme-6">Dosen PA</div>
          <div v-if="dosenPA?.dosen" class="text-gray-900">
            <span class="text-gray-900 dark:text-gray-100">{{
              dosenPA?.dosen?.NM_DOSEN
            }}</span>
            <span class="text-gray-500"> ({{ dosenPA?.dosen?.KODE }})</span>
          </div>
          <div v-else class="text-red-500">Belum Ada</div>
        </div>
      </div>
    </div>
    <div
      v-if="showContact"
      class="mt-6 lg:mt-0 flex-1 dark:text-gray-300 px-5 border-r border-gray-200 dark:border-dark-5 border-t lg:border-t-0 pt-5 lg:pt-0"
    >
      <div class="font-medium text-center lg:text-left lg:mt-3">Kontak</div>
      <div
        class="flex flex-col justify-center items-center lg:items-start mt-4"
      >
        <div class="truncate sm:whitespace-normal flex items-center">
          <MailIcon class="w-4 h-4 mr-2" />
          {{ pesertadidik?.email }}
        </div>
        <div class="truncate sm:whitespace-normal flex items-center mt-1">
          <PhoneIcon class="w-4 h-4 mr-2" /> {{ pesertadidik?.no_hp }}
        </div>
        <div class="truncate sm:whitespace-normal flex items-center mt-1">
          <PhoneCallIcon class="w-4 h-4 mr-2" /> {{ pesertadidik?.no_tel_rmh }}
        </div>
      </div>
    </div>
    <div
      class="mt-6 lg:mt-0 flex flex-6 items-center justify-center px-5 border-t lg:border-0 border-gray-200 dark:border-dark-5 pt-5 lg:pt-0"
    >
      <div class="text-center rounded-md w-20 py-3">
        <div class="font-medium text-theme-1 dark:text-theme-10 text-xl">
          {{ parseFloat(kuliahMhsLast?.ipk).toFixed(2) }}
        </div>
        <div class="text-gray-600">IPK</div>
      </div>
      <div class="text-center rounded-md w-20 py-3">
        <div class="font-medium text-theme-1 dark:text-theme-10 text-xl">
          {{ parseInt(kuliahMhsLast?.sks_total) }}
        </div>
        <div class="text-gray-600">SKS</div>
      </div>
    </div>
  </div>
</template>

<script>
import SubInfoPD from "@/components/mahasiswa/SubInfoPD";
export default {
  name: "InfoMahasiswa",
  components: { SubInfoPD },
  props: {
    pesertadidik: {
      type: Object,
      default: null,
    },
    dosenPA: {
      type: Object,
      default: null,
    },
    kuliahMhs: {
      type: Object,
      default: null,
    },
    kuliahMhsLast: {
      type: Object,
      default: null,
    },
    showContact: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    pesertadidik: {
      handler: function () {},
      immediate: true,
    },
    dosenPA: {
      handler: function () {},
      immediate: true,
    },
    kuliahMhs: {
      handler: function () {},
      immediate: true,
    },
    kuliahMhsLast: {
      handler: function () {},
      immediate: true,
    },
  },
  mounted() {},
};
</script>

<style scoped></style>
