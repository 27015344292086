<template>
  <div>
    <div class="intro-y md:flex items-center mt-8">
      <h2
        class="text-lg font-medium md:mr-auto md:border-0 border-b border-gray-400"
      >
        Profil Mahasiswa
      </h2>
      <div class="w-full md:w-auto md:flex md:mt-4 md:mt-0 mt-3">
        <CariMahasiswa />
        <router-link
          :to="{ name: 'pesertadidik' }"
          class="btn btn-success shadow-md md:ml-2 mt-2 md:mt-0"
          >List Peserta Didik</router-link
        >
      </div>
    </div>
    <!-- BEGIN: Profile Info -->
    <div class="intro-y box px-5 pt-5 mt-5">
      <InfoMahasiswa
        v-if="dataPD?.nm_pd"
        :pesertadidik="dataPD"
        :dosen-p-a="dosenPA"
        :kuliah-mhs-last="kuliahMhsLast"
      />
      <div
        class="nav nav-tabs flex-col sm:flex-row justify-center lg:justify-start"
        role="tablist"
      >
        <TabNav
          :tab-nav-id="'data-pribadi'"
          :tab-label="'data-pribadi'"
          @click="tabactive = 'data-pribadi'"
        >
          <UserIcon class="w-4 h-4 mr-2" /> Data Pribadi
        </TabNav>
        <TabNav
          :tab-nav-id="'data-registrasi'"
          :tab-label="'data-registrasi'"
          @click="tabactive = 'data-registrasi'"
        >
          <DatabaseIcon class="w-4 h-4 mr-2" /> Data Registrasi
        </TabNav>
        <TabNav
          :tab-nav-id="'kuliah-mhs'"
          :tab-label="'kuliah-mhs'"
          @click="tabactive = 'kuliah-mhs'"
        >
          <CompassIcon class="w-4 h-4 mr-2" /> Kuliah Mahasiswa
        </TabNav>
        <TabNav
          :tab-nav-id="'aktivitas-kuliah'"
          :tab-label="'aktivitas-kuliah'"
          @click="tabactive = 'aktivitas-kuliah'"
        >
          <ZapIcon class="w-4 h-4 mr-2" /> Aktivitas Kuliah
        </TabNav>
        <TabNav
          :tab-nav-id="'jadwal'"
          :tab-label="'jadwal'"
          @click="tabactive = 'jadwal'"
        >
          <CalendarIcon class="w-4 h-4 mr-2" /> Jadwal
        </TabNav>
        <TabNav
          :tab-nav-id="'absensi'"
          :tab-label="'absensi'"
          @click="tabactive = 'absensi'"
        >
          <UserCheckIcon class="w-4 h-4 mr-2" /> Absensi
        </TabNav>

        <TabNav
          :tab-nav-id="'daftar-nilai'"
          :tab-label="'daftar-nilai'"
          @click="tabactive = 'daftar-nilai'"
        >
          <ListIcon class="w-4 h-4 mr-2" /> Daftar Nilai
        </TabNav>
        <TabNav
          :tab-nav-id="'transkrip'"
          :tab-label="'transkrip'"
          @click="tabactive = 'transkrip'"
        >
          <FileTextIcon class="w-4 h-4 mr-2" /> Transkrip
        </TabNav>
      </div>
    </div>
    <!-- END: Profile Info -->
    <div class="tab-content mt-5">
      <div
        id="data-pribadi-tab"
        class="tab-pane active"
        role="tabpanel"
        aria-labelledby="profile-tab"
      >
        <div class="grid grid-cols-12 gap-6">
          <!-- BEGIN: Biodata Mahasiswa -->
          <div class="intro-y box col-span-12 lg:col-span-12">
            <div
              class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
            >
              <h2 class="font-medium text-base mr-auto">Biodata Mahasiswa</h2>
            </div>
            <div v-if="tabactive === 'data-pribadi'" class="tab-content">
              <Biodata :pesertadidik="dataPD" />
            </div>
          </div>
          <!-- END: Biodata Mahasiswa -->
        </div>
      </div>
      <div
        id="data-registrasi-tab"
        class="tab-pane"
        role="tabpanel"
        aria-labelledby="data-registrasi-tab"
      >
        <div class="grid grid-cols-12 gap-6">
          <!-- BEGIN: Data Registrasi -->
          <div class="intro-y box col-span-12 lg:col-span-12">
            <div
              class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
            >
              <h2 class="font-medium text-base mr-auto">
                Data Registrasi Peserta Didik
              </h2>
            </div>
            <div class="p-5">
              <div class="tab-content">
                <div class="overflow-x-auto">
                  <DataRegPD
                    v-if="tabactive === 'data-registrasi'"
                    :reg-p-d="dataPD?.reg_pds"
                  ></DataRegPD>
                </div>
              </div>
            </div>
          </div>
          <!-- END: Data Registrasi -->
        </div>
      </div>
      <div
        id="kuliah-mhs-tab"
        class="tab-pane"
        role="tabpanel"
        aria-labelledby="profile-tab"
      >
        <div class="grid grid-cols-12 gap-6">
          <!-- BEGIN: Aktivitas Kuliah Mahasiswa -->
          <div class="intro-y box col-span-12 lg:col-span-12">
            <div
              class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
            >
              <h2 class="font-medium text-base mr-auto">
                Aktivitas Perkuliahan Mahasiswa
              </h2>
            </div>
            <div class="p-5">
              <div class="tab-content">
                <div class="overflow-x-auto">
                  <DataKuliahMahasiswa
                    v-if="tabactive === 'kuliah-mhs'"
                    :id-reg-p-d="idRegPD"
                    :reg-p-d="dataPD?.reg_pds"
                  ></DataKuliahMahasiswa>
                </div>
              </div>
            </div>
          </div>
          <!-- END: Aktivitas Kuliah Mahasiswa  -->
        </div>
      </div>

      <div
        id="aktivitas-kuliah-tab"
        class="tab-pane"
        role="tabpanel"
        aria-labelledby="profile-tab"
      >
        <div class="grid grid-cols-12 gap-6">
          <!-- BEGIN: Aktivitas  Mahasiswa -->
          <div class="intro-y box col-span-12 lg:col-span-12">
            <div
              class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
            >
              <h2 class="font-medium text-base mr-auto">Aktivitas Mahasiswa</h2>
            </div>
            <div class="p-5">
              <div class="tab-content">
                <DataAktKuliah
                  v-if="tabactive === 'aktivitas-kuliah'"
                  :id-reg-p-d="idRegPD"
                  :reg-p-d="dataPD?.reg_pds"
                ></DataAktKuliah>
              </div>
            </div>
          </div>
          <!-- END: Aktivitas Mahasiswa  -->
        </div>
      </div>

      <div
        id="transkrip-tab"
        class="tab-pane"
        role="tabpanel"
        aria-labelledby="transkrip-tab"
      >
        <div class="grid grid-cols-12 gap-6">
          <!-- BEGIN: Transkrip -->
          <div class="intro-y box col-span-12 lg:col-span-12">
            <div
              class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
            >
              <h2 class="font-medium text-base mr-auto">Transkrip</h2>
              <button class="btn btn-success" @click="cetak">
                Cetak Transkrip
              </button>
            </div>
            <div class="p-5">
              <div class="tab-content">
                <div class="overflow-x-auto">
                  <NilaiTranskrip
                    v-if="tabactive === 'transkrip'"
                    ref="nilaiTrans"
                    :detailmahasiswa="dataPD?.reg_pd"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- END: Transkrip -->
        </div>
      </div>

      <TabContent
        :title="'Jadwal Kuliah Mahasiswa'"
        tab-label="jadwal"
        :tab-id="'jadwal'"
      >
        <template #tools>
          <button @click="timetablemode = !timetablemode">
            {{ timetablemode ? "Normal Table" : "Time Table" }}
          </button>
        </template>
        <div class="p-5">
          <div v-if="tabactive === 'jadwal'" class="tab-content">
            <div class="overflow-x-auto">
              <JadwalKuliahTimeTable
                v-if="timetablemode"
                :detail-mahasiswa="dataPD?.reg_pd"
                :smt="semester"
              ></JadwalKuliahTimeTable>
              <JadwalKuliah
                v-else
                :detail-mahasiswa="dataPD?.reg_pd"
                :smt="semester"
              ></JadwalKuliah>
            </div>
          </div>
        </div>
      </TabContent>
      <TabContent
        :title="'Absensi Mahasiswa'"
        tab-label="absensi"
        :tab-id="'absensi'"
      >
        <div class="p-5">
          <div class="tab-content">
            <div class="overflow-x-auto">
              <RekapAbsensiPerMhs
                v-if="tabactive === 'absensi'"
                :detail-mahasiswa="dataPD?.reg_pd"
                :smt="semester"
              ></RekapAbsensiPerMhs>
            </div>
          </div>
        </div>
      </TabContent>

      <TabContent
        :title="
          daftarnilai == 'dns'
            ? ' Daftar Nilai Semester (DNS)'
            : 'Daftar Nilai Transisi / Transfer'
        "
        tab-label="daftar-nilai"
        :tab-id="'daftar-nilai'"
      >
        <template #tools>
          <button
            class="btn mr-2"
            :class="daftarnilai == 'dns' ? 'btn-primary' : 'btn-default'"
            @click="daftarnilai = 'dns'"
          >
            <ListIcon class="mr-1 w-4 h-4" />
            Daftar Nilai Semester (DNS)
          </button>
          <button
            class="btn"
            :class="daftarnilai == 'transfer' ? 'btn-primary' : 'btn-default'"
            @click="daftarnilai = 'transfer'"
          >
            <TabletIcon class="mr-1 w-4 h-4" />
            Daftar Nilai Transisi / Transfer
          </button>
        </template>
        <div class="p-5">
          <div v-if="tabactive === 'daftar-nilai'" class="tab-content">
            <NilaiSemesterMahasiswa
              v-if="daftarnilai == 'dns'"
              :info-mhs="dataPD"
              :smt="semester"
              :isaction="false"
              :detail-mahasiswa="dataPD?.reg_pd"
            />

            <ListNilaiTransferSP
              v-else
              :show-mhs-info="false"
              :idregpd="idRegPD"
              :idpd="dataPD?.id_pd"
            ></ListNilaiTransferSP>
          </div>
        </div>
      </TabContent>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import Biodata from "./Biodata";
import InfoMahasiswa from "../../../components/mahasiswa/InfoMahasiswa";
import NilaiTranskrip from "@/components/table-and-list/NilaiTranskrip";
import { mahasiswa, perkuliahan } from "@/services/models";
import DataRegPD from "@/views/mahasiswa/pesertadidik/DataRegPD";
import DataKuliahMahasiswa from "@/views/mahasiswa/pesertadidik/DataKuliahMahasiswa";
import DataAktKuliah from "@/views/mahasiswa/pesertadidik/DataAktKuliah";
import CariMahasiswa from "@/components/form/CariMahasiswa";
import NilaiSemesterMahasiswa from "@/views/mahasiswa/pesertadidik/component/NilaiSemesterMahasiswa";
import TabNav from "@/components/tabs/TabNav";
import TabContent from "@/components/tabs/TabContent";
import JadwalKuliah from "@/components/table-and-list/JadwalKuliah";
import RekapAbsensiPerMhs from "@/components/table-and-list/RekapAbsensiPerMhs";
import JadwalKuliahTimeTable from "@/components/table-and-list/jadwal-kuliah-timetable/JadwalKuliah";
import ListNilaiTransferSP from "@/views/mahasiswa/pesertadidik/component/ListNilaiTransferSp";
export default defineComponent({
  components: {
    ListNilaiTransferSP,
    JadwalKuliahTimeTable,
    RekapAbsensiPerMhs,
    JadwalKuliah,
    TabContent,
    TabNav,
    Biodata,
    NilaiSemesterMahasiswa,
    CariMahasiswa,
    DataAktKuliah,
    DataKuliahMahasiswa,
    DataRegPD,
    NilaiTranskrip,
    InfoMahasiswa,
  },
  setup() {
    const route = useRoute();
    const id = route.params.id; // read parameter id (it is reactive)
    const store = useStore();
    return {
      store,
      id,
    };
  },
  data() {
    return {
      editmode: false,
      timetablemode: false,
      daftarnilai: "dns",
      dataPD: null,
      dosenPA: null,
      idRegPD: null,
      kuliahMhsLast: null,
      tabactive: null,
      semester: null,
    };
  },
  computed: {
    savingStatus() {
      return this.isSaving;
    },
    savingButton() {
      return this.btnSave;
    },
    isSuccess() {
      return this.$store.state.main.isSuccess;
    },
    loadingStatus() {
      return this.$store.state.main.loadingStatus;
    },
    currentSemester() {
      return this.$store.state.main.currentSemester;
    },
  },
  mounted() {
    this.tabactive = "data-pribadi";
    this.semester = this.currentSemester?.id;
    this.fetchDataMahasiswa();
  },
  methods: {
    fetchDataMahasiswa() {
      mahasiswa.PesertaDidik.getData(this.id)
        .then((result) => {
          if (result?.status === 200) {
            this.dataPD = result.data.data;
            this.dosenPA = this.dataPD?.map_mhs;
            this.idRegPD = this.dosenPA?.id_reg_pd;
          }
        })
        .finally(() => {
          if (this.idRegPD?.length > 0) {
            perkuliahan.kuliahMhs
              .fetchUrl("latestKuliah/" + this.idRegPD)
              .then((result) => {
                if (result?.status === 200) {
                  this.kuliahMhsLast = result.data.data;
                }
              });
          }
        });
    },
    cetak() {
      this.$refs.nilaiTrans.cetakTranskrip();
    },
  },
});
</script>
