<template>
  <div>
    <div class="grid-cols-3 grid-cols-4 d-none"></div>
    <div v-if="oneRow" :class="'grid grid-cols-' + row + ' gap-4'">
      <div v-for="input in inputs.fields" :key="input.model">
        <div v-if="input.type === 'input'" class="input-form mt-3">
          <InputText
            :key="input.model"
            v-model.lazy="models[input.model]"
            :input-value="models[input.model]"
            :schema="input"
          />
        </div>
        <div v-if="input.type === 'select'" class="input-form mt-3">
          <label class="form-label">{{ input?.label }}</label>
          <div>
            <TailSelect
              v-model="models[input.model]"
              :options="{
                search: true,
                classNames: 'w-full',
                deselect: false,
              }"
              :name="input.model"
            >
              <option
                v-for="list in optionsData[input.model]"
                :key="list.value"
                :value="list.value"
                :selected="list.selected"
              >
                {{ list.text }}
              </option>
            </TailSelect>
          </div>
        </div>
        <div v-if="input.type === 'dateTimePicker'" class="input-form mt-3">
          <label class="form-label">{{ input?.label }}</label>
          <div class="relative">
            <div
              class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 border text-gray-600 dark:bg-dark-1 dark:border-dark-4"
            >
              <CalendarIcon class="w-4 h-4" />
            </div>
            <Litepicker
              v-model="models[input.model]"
              :options="{
                format: 'YYYY-MM-DD',
                autoApply: false,
                showWeekNumbers: true,
                dropdowns: {
                  minYear: 2020,
                  maxYear: new Date().getFullYear() + 30,
                  months: true,
                  years: true,
                },
              }"
              class="form-control pl-12"
              :name="input.model"
              placeholder="Pick the date"
            />
          </div>
        </div>

        <div v-if="input.type === 'radio'" class="input-form mt-3">
          <label class="form-label">{{ input?.label }}</label>
          <div :class="input?.radioinline ? 'flex flex-col sm:flex-row' : ''">
            <div
              v-for="list in optionsData[input.model]"
              :key="list.value"
              :class="input?.radioinline ? 'mr-2' : ''"
              class="form-check mt-2"
            >
              <input
                :id="list.id"
                v-model="models[input.model]"
                class="form-check-input"
                type="radio"
                :name="input?.model"
                :value="list.value"
              />
              <label class="form-check-label" :for="list.id">{{
                list?.text
              }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!oneRow">
      <div v-for="input in inputs.fields" :key="input.model">
        <div v-if="input.type === 'textarea'" class="input-form mt-3">
          <label class="form-label">{{ input?.label }}</label>
          <textarea v-model.lazy="models[input.model]" class="form-control" />
        </div>
        <div v-if="input.type === 'input'" class="input-form mt-3">
          <InputText
            :key="input.model"
            v-model.lazy="models[input.model]"
            :input-value="models[input.model]"
            :schema="input"
          />
        </div>
        <div v-if="!oneRow">
          <div v-if="input.type === 'dateTimePicker'" class="input-form mt-3">
            <label class="form-label">{{ input?.label }}</label>
            <div class="relative">
              <div
                class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 border text-gray-600 dark:bg-dark-1 dark:border-dark-4"
              >
                <CalendarIcon class="w-4 h-4" />
              </div>
              <Litepicker
                v-model="models[input.model]"
                :options="{
                  format: 'YYYY-MM-DD',
                  autoApply: false,
                  showWeekNumbers: true,
                  dropdowns: {
                    minYear: 2020,
                    maxYear: new Date().getFullYear() + 30,
                    months: true,
                    years: true,
                  },
                }"
                class="form-control pl-12"
                :name="input.model"
                placeholder="Pick the date"
              />
            </div>
          </div>
        </div>
        <div v-if="!oneRow">
          <div v-if="input.type === 'select'" class="input-form mt-3">
            <label class="form-label">{{ input?.label }}</label>
            <div>
              <TailSelect
                v-model="models[input.model]"
                :options="{
                  search: true,
                  classNames: 'w-full',
                  deselect: false,
                }"
                :name="input.model"
              >
                <option
                  v-for="list in optionsData[input.model]"
                  :key="list.value"
                  :value="list.value"
                  :selected="list.selected"
                >
                  {{ list.text }}
                </option>
              </TailSelect>
            </div>
          </div>
        </div>

        <div v-if="input.type === 'radio'" class="mt-3">
          <label class="form-label">{{ input?.label }}</label>
          <div :class="input?.radioinline ? 'flex flex-col sm:flex-row' : ''">
            <div
              v-for="list in optionsData[input.model]"
              :key="list.value"
              :class="input?.radioinline ? 'mr-2' : ''"
              class="form-check mt-2"
            >
              <input
                :id="list.id"
                v-model="models[input.model]"
                class="form-check-input"
                type="radio"
                :name="input?.model"
                :value="list.value"
              />
              <label class="form-check-label" :for="list.id">{{
                list?.text
              }}</label>
            </div>
          </div>
        </div>

        <div v-if="input.type === 'checkbox'" class="input-form mt-3">
          <label class="form-label">{{ input?.label }}</label>
          <div
            v-for="list in optionsData[input.model]"
            :key="list.value"
            class="form-check mt-2"
          >
            <input
              class="form-check-input"
              type="checkbox"
              :value="list.value"
              :checked="list.selected"
            />
            <label class="form-check-label">{{ list?.text }}</label>
          </div>
        </div>

        <div v-if="input.type === 'input-search'" class="input-form mt-3">
          <label class="form-label"
            >{{ input?.label }}
            <div v-if="models[input.model]?.id && models['id'] == null">
              <strong> : {{ models[input.model]?.code }}</strong>
              {{ models[input.model]?.name }}
              <span
                v-if="models[input.model]?.desc"
                class="font-bold font-italic"
              >
                - {{ models[input.model]?.desc }}
              </span>
            </div>
            <div v-if="models['id'] != null">
              <strong>
                {{
                  optionsData[input.model.replace("id_", "")][0]?.data[
                    optionsData[input.model.replace("id_", "")][0]?.field_code
                  ]
                }}</strong
              >
              {{
                optionsData[input.model.replace("id_", "")][0]?.data[
                  optionsData[input.model.replace("id_", "")][0]?.field_name
                ]
              }}
            </div>
          </label>
          <div class="grid">
            <AutoComplete
              v-if="models['id'] == null"
              v-model="models[input.model]"
              :suggestions="filteredOptions[input.model]"
              field="name"
              force-selection
              :dropdown="true"
              :aria-placeholder="input?.label"
              @complete="searchOption($event, input.model)"
            >
              <template #item="slotProps">
                <div class="item">
                  <div>
                    <strong> {{ slotProps.item.code }}</strong>
                    {{ slotProps.item.name }}
                    <span
                      v-if="slotProps.item.desc"
                      class="font-bold font-italic"
                    >
                      - {{ slotProps.item.desc }}
                    </span>
                  </div>
                </div>
              </template>
            </AutoComplete>
          </div>
        </div>
        <!-- END: Basic Select -->
      </div>
    </div>
  </div>
</template>

<script>
import InputText from "./InputText";
import { HTTP } from "@/services";
//import { reactive, ref } from "vue";
//import { maxLength, minLength, required } from "@vuelidate/validators";
//import { useVuelidate } from "@vuelidate/core";

export default {
  name: "GenerateForm",
  components: {
    InputText,
  },
  props: {
    oneRow: {
      type: Boolean,
      default: false,
    },
    row: {
      type: Number,
      default: 2,
    },
    inputs: {
      type: Object,
      default: () => ({}),
    },
    model: {
      type: Object,
      default: () => ({}),
    },
    optionsData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    let models = props.model;
    let opts = props.optionsData;
    let optn = {};
    let seloptn = {};
    for (var opt in opts) {
      optn[opt] = null;
      seloptn[opt] = null;
    }
    let selectedOption = seloptn;
    let filteredOptions = optn;

    //Belum dipakai
    /*const rules = [];
    const validate = [];
    props.inputs.fields.forEach(schema => {
      if (schema.inputType === "text" && schema.type === "input") {
        rules[schema.model] = {
          input: {
            required: schema.rule?.required ? required : false,
            minLength: schema.rule?.minLength
              ? minLength(schema.rule?.minLength)
              : false,
            maxLength: schema.rule?.maxLength
              ? maxLength(schema.rule?.maxLength)
              : false
          }
        };
        let ins = models[schema.model];
        validate[schema.model] = useVuelidate(rules[schema.model], { ins });
      }
    });*/
    return {
      models,
      selectedOption,
      filteredOptions,
    };
  },
  data() {
    return {
      searchOptions: null,
    };
  },
  watch: {
    models: {
      handler: function (newData) {
        console.log(newData);
      },
    },
  },
  mounted() {},

  methods: {
    searchOption(event, m) {
      setTimeout(() => {
        let opt = this.$store.getters["main/listOptions"][m];
        if (!event.query.length) {
          this.filteredOptions[m] = [...opt];
        } else {
          HTTP.get(this.optionsData[m][0]?.search_url, {
            params: {
              q: event.query,
            },
          }).then((result) => {
            this.$store.dispatch("main/listOptions", {
              [m]: result.data,
            });
            opt = this.$store.getters["main/listOptions"][m];
            this.filteredOptions[m] = [...opt];
          });
        }
      }, 100);
    },
  },
};
</script>
