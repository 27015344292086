<template>
  <div class="relative">
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">
        <router-link :to="{ name: 'kelompok-honor' }">
          <ArrowLeftCircleIcon></ArrowLeftCircleIcon>
        </router-link>
        {{ id ? "Edit" : "Tambah" }} Kelompok Honor
      </h2>
    </div>

    <div class="intro-y box p-5 mt-5">
      <div class="grid sm:grid-cols-2 grid-cols-1 gap-2">
        <div class="mb-2">
          <label class="form-label">Nama Kelompok Honor</label>
          <input v-model="nm_kelompok_honor" type="text" class="form-control" />
          <span
            v-if="
              messageValidator?.nm_kelompok_honor &&
              messageValidator?.ket_kelompok_honor.length > 0
            "
            class="text-red-600"
            >{{ messageValidator?.kode[0] }}</span
          >
        </div>

        <div class="mb-2">
          <label class="form-label">Keterangan</label>
          <input
            v-model="ket_kelompok_honor"
            type="text"
            class="form-control"
          />
          <span
            v-if="
              messageValidator?.ket_kelompok_honor &&
              messageValidator?.ket_kelompok_honor.length > 0
            "
            class="text-red-600"
            >{{ messageValidator?.ket_kelompok_honor[0] }}</span
          >
        </div>
      </div>

      <div class="my-3">
        <button class="btn btn-primary w-full" @click="simpanKelompok">
          SIMPAN
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { keuangan } from "@/services/models";
import router from "@/router";

export default defineComponent({
  setup() {
    const route = useRoute();
    const id = route.params.id; // read parameter id (it is reactive)
    const store = useStore();
    return {
      store,
      id,
    };
  },
  data() {
    return {
      nm_kelompok_honor: "",
      ket_kelompok_honor: "",
      kelompokDetail: [],
      messageValidator: [],
    };
  },
  watch: {
    id: {
      handler: function () {
        this.getKelompok();
      },
      immediate: true,
    },
  },
  methods: {
    getKelompok() {
      if (this.id) {
        keuangan.KelompokHonor.getData(this.id).then((res) => {
          if (res.data.code === 200) {
            let detail = res.data.datas;
            this.kelompokDetail = detail;
            this.nm_kelompok_honor = detail.nm_kelompok_honor;
            this.ket_kelompok_honor = detail.ket_kelompok_honor;
          }
        });
      }
    },
    simpanKelompok() {
      if (this.id) {
        let val = {
          nm_kelompok_honor: this.nm_kelompok_honor,
          ket_kelompok_honor: this.ket_kelompok_honor,
        };
        keuangan.KelompokHonor.updateData(this.id, val).then((res) => {
          if (res.data.code === 200) {
            router.push({
              name: "kelompok-honor",
            });
          } else {
            this.messageValidator = res.data.message;
          }
        });
      } else {
        let val = {
          nm_kelompok_honor: this.nm_kelompok_honor,
          ket_kelompok_honor: this.ket_kelompok_honor,
        };

        keuangan.KelompokHonor.insertData(val).then((res) => {
          if (res.data.code === 200) {
            router.push({
              name: "kelompok-honor",
            });
          } else {
            this.messageValidator = res.data.message;
          }
        });
      }
    },
  },
});
</script>
