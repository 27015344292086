<template>
  <div class="relative" style="min-height: 300px">
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y">
      <LoadingSpinner :is-loading="loadingStatus" />
      <div class="grid grid-cols-12">
        <div class="md:col-span-3 col-span-12 mb-4">
          <label class="form-label">Pilih Semester</label>
          <select
            v-model="semester"
            class="form-control focus:outline-none focus:ring-0"
          >
            <option
              v-for="smtr in listSemester"
              :key="smtr.id"
              :value="smtr.value"
              :selected="smtr.selected"
            >
              {{ smtr.text }}
            </option>
          </select>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <JadwalKuliahTable :datamk="datamk"></JadwalKuliahTable>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { defineComponent } from "vue";

import LoadingSpinner from "@/components/loading-spinner/Main";
import { master, perkuliahan } from "@/services/models";
import JadwalKuliahTable from "@/components/table-and-list/jadwal-kuliah-timetable/JadwalKuliahTable";

export default defineComponent({
  components: {
    JadwalKuliahTable,
    LoadingSpinner,
  },
  props: {
    detailMahasiswa: {
      type: Object,
      default: null,
    },
    smt: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      semester: null,
      listSemester: [],
      datamk: [],
    };
  },
  computed: {
    loadingStatus() {
      return this.$store.state.main.loadingStatus;
    },
    currentSemester() {
      return this.$store.state.main.currentSemester;
    },
  },
  watch: {
    smt: {
      handler: function (newval) {
        this.semester = newval;
      },
      immediate: true,
    },
    currentSemester: {
      handler: function () {
        this.$store.state.main.currentSemester;
      },
    },
    semester: {
      handler: function () {
        this.getItems();
      },
    },
  },

  beforeMount() {
    this.fetchSemester();
  },
  mounted() {
    this.getItems();
  },
  methods: {
    getItems() {
      perkuliahan.JadwalKelasKuliah.get(
        "jadwal_mhs/" + this.detailMahasiswa?.id_reg_pd,
        { params: { semester: this.semester } }
      ).then((result) => {
        this.datamk = [];
        const data = result.data.data;
        data.forEach((element) => {
          this.datamk.push({
            kelas: element?.KELAS,
            nama_mk: element?.mk?.nm_mk,
            kode_mk: element?.KODE_MK,
            sks_mk: element?.mk?.sks_mk,
            waktu: element?.waktu,
            dosen: element?.dosen_pengajar?.NM_DOSEN,
            id_kelas: element?.id_kls,
            prodi: element?.kelas?.sms?.prodi,
          });
        });
      });
    },
    fetchSemester() {
      let that = this;
      this.listSemester = [];
      this.listSemester.push({
        id: "0",
        value: "0",
        text: "Pilih Semester",
        selected: false,
      });
      let path =
        this.detailMahasiswa?.id_reg_pd === undefined
          ? "smt_kuliah_mhs"
          : "smt_kuliah_mhs/" + this.detailMahasiswa?.id_reg_pd;

      master.SemesterAkad.getCustomPath(path).then((result) => {
        if (result.status === 200) {
          result.data.data.forEach((item) => {
            let newoption = {
              id: item.id_smt,
              value: item.id_smt,
              text: item.nm_smt,
              selected: that.semester.toString() === item.id_smt,
            };

            if (newoption.selected === true)
              that.semester = item.id_smt.toString();
            that.listSemester.push(newoption);
          });
        }
      });
    },
  },
});
</script>
