<template>
  <div>
    <label class="form-label">{{ schema?.label }}</label>
    <input
      :id="schema?.model"
      ref="schema?.model"
      :type="schema?.inputType"
      :value="inputValue"
      class="form-control"
      :placeholder="schema?.placeholder"
      :required="!!schema?.rule?.required"
      :maxlength="schema?.rule?.maxLength"
      :name="schema?.model"
      autocomplete="off"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <!-- <template v-if="validation.input.$error">
      <div
        v-for="(error, index) in validation.input.$errors"
        :key="index"
        class="text-theme-6 mt-2"
      >
        {{ error.$message }}
      </div>
    </template>-->
  </div>
</template>
<script>
//<!--      v-model.trim="validation.input.$model"-->
//:class="{ 'border-theme-6': validate.input.$error }"
import { defineComponent } from "vue";
export default defineComponent({
  name: "InputText",
  props: {
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Type your input",
    },
    schema: {
      type: Object,
      default: () => ({}),
    },
    inputValue: {
      type: [String, Number],
      default: "",
    },
  },
  /*setup(props) {
    let input = ref("");
    const formData = reactive({
      input: ""
    });
    //v-model="validate.input.$model"
    const rules = {
      input: {
        required: props.schema.rule?.required ? required : false,
        minLength: props.schema.rule?.minLength
          ? minLength(props.schema.rule?.minLength)
          : false,
        maxLength: props.schema.rule?.maxLength
          ? maxLength(props.schema.rule?.maxLength)
          : false
      }
    };
    const validation = useVuelidate(rules, formData);
    return {
      validation,
      formData,
      input
    };
  }*/
});
</script>
