<template>
  <div class="relative">
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">
        <router-link :to="{ name: 'master-keuangan' }">
          <ArrowLeftCircleIcon></ArrowLeftCircleIcon>
        </router-link>
        Tambah Master Biaya Kuliah
      </h2>
    </div>

    <div class="intro-y box p-5 mt-5">
      <div v-if="loading" class="block-loading">
        <LoadingIcon icon="three-dots" class="w-8 h-8" />
        <div class="text-center text-xs mt-2">
          Loading data... Please Wait...
        </div>
      </div>
      <AlertSuccess
        v-if="statusSimpan.status == true"
        :dismissable="true"
        :message="statusSimpan.msg"
        close-and-clear="closeAndClear"
      ></AlertSuccess>
      <div class="grid sm:grid-cols-3 grid-cols-1 gap-2">
        <div class="mb-2">
          <label class="form-label">Tahun</label>
          <select v-model="insTahun" class="form-select">
            <option
              v-for="(tahun, index) in listTahun"
              :key="index"
              :value="tahun"
            >
              {{ tahun }}
            </option>
          </select>
          <span
            v-if="messageValidator?.tahun && messageValidator?.tahun.length > 0"
            class="text-red-600"
            >{{ messageValidator?.tahun[0] }}</span
          >
        </div>

        <div class="mb-2">
          <label class="form-label">Fakultas</label>
          <select v-model="faculty" class="form-select">
            <option v-for="fak in faculties" :key="fak.id" :value="fak.value">
              {{ fak.text }}
            </option>
          </select>
        </div>

        <div class="mb-2">
          <label class="form-label"
            ><input
              v-model="chekProdi"
              type="checkbox"
              @click="
                () => {
                  !chekProdi;
                }
              "
            />
            Program Studi</label
          >
          <select v-model="prodi" class="form-select" :disabled="!chekProdi">
            <option
              v-for="item in listProdi"
              :key="item.id"
              :value="item.value"
            >
              {{ item.text }}
            </option>
          </select>
          <span
            v-if="messageValidator?.prodi && messageValidator?.prodi.length > 0"
            class="text-red-600"
            >{{ messageValidator?.prodi[0] }}</span
          >
        </div>

        <div class="mb-2">
          <label class="form-label">Jenis</label>
          <TailSelect
            v-model="jenis"
            :options="{
              search: true,
              classNames: 'w-full',
              deselect: false,
            }"
          >
            <option
              v-for="item in listJenis"
              :key="item.id"
              :value="item.value"
              :selected="item.selected"
            >
              {{ item.text }}
            </option>
          </TailSelect>
          <span
            v-if="messageValidator?.jenis && messageValidator?.jenis.length > 0"
            class="text-red-600"
            >{{ messageValidator?.jenis[0] }}</span
          >
        </div>

        <div v-if="isShowWaktu" class="mb-2">
          <label class="form-label">Waktu kuliah</label>
          <select v-model="waktu" class="form-select">
            <option
              v-for="(time, index) in listwaktu"
              :key="index"
              :value="time.kode"
            >
              {{ time.nama }}
            </option>
          </select>
          <span
            v-if="messageValidator?.waktu && messageValidator?.waktu.length > 0"
            class="text-red-600"
            >{{ messageValidator?.waktu[0] }}</span
          >
        </div>

        <div v-else-if="isShowGrade" class="mb-2">
          <label class="form-label">Grade</label>
          <select v-model="waktu" class="form-select">
            <option
              v-for="(time, index) in listGrade"
              :key="index"
              :value="time.kode"
            >
              {{ time.nama }}
            </option>
          </select>
          <span
            v-if="messageValidator?.waktu && messageValidator?.waktu.length > 0"
            class="text-red-600"
            >{{ messageValidator?.waktu[0] }}</span
          >
        </div>

        <div class="mb-2">
          <label class="form-label">Nominal</label>
          <input
            v-model="nominal"
            type="number"
            class="form-control"
            @keyup="formatRupiah(nominal)"
          />
          <span
            v-if="
              messageValidator?.nominal && messageValidator?.nominal.length > 0
            "
            class="text-red-600"
            >{{ messageValidator?.nominal[0] }}</span
          >
          <span v-else class="text-green-800 font-medium">
            {{ rupiahNominal }}
          </span>
        </div>
      </div>

      <div class="my-3">
        <button class="btn btn-primary w-full" @click="simpanBiaya">
          SIMPAN
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { HTTPKEU } from "../../../services/http";
import { master, keuangan } from "@/services/models";
import router from "@/router";
import AlertSuccess from "../../../components/alerts/AlertSuccess";

export default defineComponent({
  components: { AlertSuccess },
  data() {
    return {
      loading: false,
      jenis: "",
      waktu: "P",
      faculty: "0",
      prodi: "",
      nominal: "",
      chekProdi: false,
      insTahun: new Date().getFullYear(),
      isShowWaktu: false,
      listwaktu: [
        { kode: "P", nama: "Pagi" },
        { kode: "S", nama: "Sore" },
        { kode: "K", nama: "Kelas Karyawam" },
        { kode: "M", nama: "Kampus Merdeka" },
      ],
      isShowGrade: false,
      listGrade: [
        { kode: "G1", nama: "Grade 1" },
        { kode: "G2", nama: "Grade 2" },
        { kode: "G3", nama: "Grade 3" },
        { kode: "G4", nama: "Grade 4" },
        { kode: "JTTG1", nama: "JTT Gel 1" },
        { kode: "JTTG2", nama: "JTT Gel 2" },
        { kode: "JP", nama: "Jalur Prestasi" },
      ],
      listTahun: [],
      listJenis: [],
      prodies: [],
      listProdi: [],
      faculties: [],
      messageValidator: [],
      rupiahNominal: "",
      statusSimpan: {
        msg: null,
        status: null,
      },
    };
  },
  watch: {
    faculty: {
      handler: function () {
        this.filterProdi();
      },
    },
    jenis: {
      handler: function () {
        this.openChoose();
      },
    },
  },
  mounted() {
    this.tahunlist();
    this.fetchFakultas();
    this.jenislist();
    this.fetchProdi();
  },
  methods: {
    formatRupiah(nominal) {
      if (nominal) {
        let rupiahLocal = Intl.NumberFormat("id-ID");
        this.rupiahNominal = "Rp." + rupiahLocal.format(nominal);
      } else {
        this.rupiahNominal = "";
      }
    },
    tahunlist() {
      let nowYear = new Date().getFullYear();
      // eslint-disable-next-line for-direction
      for (let i = nowYear + 1; i > nowYear - 8; i--) {
        this.listTahun.push(i);
      }
    },
    simpanBiaya() {
      this.loading = true;
      let val = {
        tahun: this.insTahun,
        fakultas: this.faculty,
        checkProdi: this.chekProdi,
        prodi: this.prodi,
        jenis: this.jenis,
        waktu: this.waktu,
        nominal: this.nominal,
      };

      keuangan.BiayaKuliah.insertData(val)
        .then((res) => {
          if (res.data.code === 200) {
            this.statusSimpan.status = true;
            this.statusSimpan.msg = res.data.message;
          } else {
            this.messageValidator = res.data.message;
            this.statusSimpan.status = false;
            this.statusSimpan.msg = res.data.message;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    jenislist() {
      HTTPKEU.get("list/jenisbiaya").then((res) => {
        this.listJenis = [];
        let opt = {
          id: 0,
          value: "",
          sub: "",
          text: "Pilih Jenis Beasiswa",
          selected: true,
        };
        this.listJenis.push(opt);

        res.data.forEach((item) => {
          const opt = {
            id: item.id,
            value: item.id,
            sub: item.sub_jenis,
            text: `${item.keterangan}`,
            selected: false,
          };
          this.listJenis.push(opt);
        });
      });
    },
    openChoose() {
      this.isShowWaktu = false;
      this.isShowGrade = false;

      let pilihan = this.listJenis.filter((fil) => {
        return fil.id == this.jenis;
      });

      if (pilihan[0].sub) {
        if (pilihan[0].sub[0] == "kelompok_kelas") {
          this.isShowWaktu = true;
          this.waktu = "P";
        } else if (pilihan[0].sub[0] == "grade") {
          this.isShowGrade = true;
          this.waktu = "G1";
        } else {
          this.waktu = "";
        }
      }
    },
    fetchFakultas() {
      let that = this;
      this.faculties.push({
        id: "0",
        value: "0",
        text: "Pilih Fakultas",
        selected: false,
      });
      master.MasterFakultas.getAllActive().then((result) => {
        if (result?.status === 200) {
          result?.data?.data.forEach((item) => {
            let newoption = {
              id: item.id,
              value: item.id,
              text: item.nama,
              kode_pddikti: item.kode_pddikti,
              nama: item.nama,
              nama_alias: item.nama_alias,
              id_sp: item.id_sp,
              selected: that.current?.id === item.id,
            };
            if (newoption.selected === true) that.faculty = item.id;
            that.faculties.push(newoption);
          });
        }
      });
    },
    fetchProdi() {
      this.prodies.push({
        id: 0,
        value: "",
        text: "Pilih Prodi",
        selected: false,
      });
      master.Prodi.getAllActive().then((result) => {
        if (result.status === 200) {
          result?.data?.data.forEach((item) => {
            let newoption = {
              id: item.id,
              id_fakultas: item.id_fakultas,
              value: item.id,
              text: item.nama,
            };
            this.prodies.push(newoption);
          });
        }
      });
    },
    filterProdi() {
      this.listProdi = [];

      let filterProdi = this.prodies.filter((fil) => {
        return fil.id_fakultas == this.faculty;
      });

      this.listProdi = filterProdi;
    },
    closeAndClear() {
      this.statusSimpan.status = null;
      this.statusSimpan.msg = null;
    },
  },
});
</script>
