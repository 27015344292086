<template>
  <div class="relative">
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Ubah Master Jenis Biaya</h2>
    </div>

    <div class="intro-y box p-5 mt-5">
      <div class="grid sm:grid-cols-2 grid-cols-1 gap-2">
        <div class="mb-2">
          <label class="form-label">Nama</label>
          <input v-model="nama" class="form-control" />
          <span
            v-if="messageValidator?.nama && messageValidator?.nama.length > 0"
            class="text-red-600"
            >{{ messageValidator?.nama[0] }}</span
          >
        </div>

        <div class="mb-2">
          <label class="form-label">Keterangan</label>
          <input v-model="keterangan" class="form-control" />
          <span
            v-if="
              messageValidator?.keterangan &&
              messageValidator?.keterangan.length > 0
            "
            class="text-red-600"
            >{{ messageValidator?.keterangan[0] }}</span
          >
        </div>
      </div>

      <div class="my-3">
        <button class="btn btn-primary w-full" @click="simpanJenis">
          SIMPAN
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { keuangan } from "@/services/models";
import router from "@/router";

export default defineComponent({
  setup() {
    const route = useRoute();
    const id = route.params.id; // read parameter id (it is reactive)
    const store = useStore();
    return {
      store,
      id,
    };
  },
  data() {
    return {
      nama: "",
      keterangan: "",
      messageValidator: [],
    };
  },
  mounted() {
    this.getJenisBiaya();
  },
  methods: {
    getJenisBiaya() {
      keuangan.JenisBiaya.get(this.id).then((res) => {
        if (res.data.code === 200) {
          this.nama = res.data.datas.nama;
          this.keterangan = res.data.datas.keterangan;
        }
      });
    },
    simpanJenis() {
      let val = {
        nama: this.nama,
        keterangan: this.keterangan,
      };
      keuangan.JenisBiaya.updateData(this.id, val).then((res) => {
        if (res.data.code === 200) {
          router.push({
            name: "jenis-biaya",
          });
        } else {
          this.messageValidator = res.data.message;
        }
      });
    },
  },
});
</script>
