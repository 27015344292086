<template>
  <div class="relative">
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Tambah Master Jenis Biaya</h2>
    </div>

    <div class="intro-y box p-5 mt-5">
      <div class="grid sm:grid-cols-2 grid-cols-1 gap-2">
        <div class="mb-2">
          <label class="form-label">Nama</label>
          <input v-model="nama" class="form-control" />
          <span
            v-if="
              messageValidator?.nama &&
              messageValidator?.nama.length > 0
            "
            class="text-red-600"
            >{{ messageValidator?.nama[0] }}</span
          >
        </div>

        <div class="mb-2">
          <label class="form-label">Keterangan</label>
          <input v-model="keterangan" class="form-control" />
          <span
            v-if="
              messageValidator?.keterangan &&
              messageValidator?.keterangan.length > 0
            "
            class="text-red-600"
            >{{ messageValidator?.keterangan[0] }}</span
          >
        </div>
      </div>

      <div class="my-3">
        <button class="btn btn-primary w-full" @click="simpanJenisBiaya">
          SIMPAN
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { keuangan } from "@/services/models";
import router from "@/router";

export default defineComponent({
  data() {
    return {
      nama: "",
      keterangan: "",
      messageValidator: [],
    };
  },
  methods: {
    simpanJenisBiaya() {
      let val = {
        nama: this.nama,
        keterangan: this.keterangan,
      };
      keuangan.JenisBiaya.insertData(val).then((res) => {
        if (res.data.code === 200) {
          router.push({
            name: "jenis-biaya",
          });
        } else {
          this.messageValidator = res.data.message;
        }
      });
    },
  },
});
</script>
