<template>
  <div v-if="!loading" class="intro-y col-span-12 lg:col-span-6">
    <div class="grid grid-cols-12 gap-0 mb-3">
      <dl class="col-span-12 lg:col-span-6">
        <DataList
          :gray="true"
          :dd="pesertadidik.nm_pd"
          :dt="'Nama Peserta Didik'"
        ></DataList>
        <DataList
          :white="true"
          :dd="$h.jenisKelamin(pesertadidik.jk)"
          :dt="'Jenis Kelamin'"
        ></DataList>
        <DataList :gray="false" :dd="pesertadidik.jln" :dt="'Alamat'">
          <template #dd>
            <ul role="list" class="">
              <li class="pr-4 py-3 flex items-center justify-between text-sm">
                <div class="w-0 flex-1 flex items-center">
                  <span class="flex-shrink-0 h-5 w-5 text-gray-400"> RT </span>
                  <span class="ml-2 flex-1 w-0 truncate">
                    {{ pesertadidik.rt }}
                  </span>
                </div>
                <div class="w-0 flex-1 flex items-center">
                  <span class="flex-shrink-0 h-5 w-5 text-gray-400"> RW </span>
                  <span class="ml-2 flex-1 w-0 truncate">
                    {{ pesertadidik.rw }}
                  </span>
                </div>
                <div class="ml-4 flex-shrink-0">
                  <span class="flex-shrink-0 h-5 w-5 text-gray-400">
                    KODE POS
                  </span>
                  <span class="ml-2 flex-1 w-0 truncate">
                    {{ pesertadidik.kode_pos }}
                  </span>
                </div>
              </li>
              <li class="pr-4 pt-3 flex items-center justify-between text-sm">
                <div class="w-0 flex-1 flex items-center">
                  <span class="flex-shrink-0 h-5 text-gray-400">
                    Desa / Kelurahan
                  </span>
                  <span class="ml-2 flex-1 w-0 truncate">
                    {{ pesertadidik.ds_kel }}
                  </span>
                </div>
                <div class="ml-4 flex-shrink-0">
                  <span class="flex-shrink-0 h-5 text-gray-400"> Dusun </span>
                  <span class="ml-2 flex-1 w-0 truncate">
                    {{ pesertadidik.nm_dsn }}
                  </span>
                </div>
              </li>
              <li class="pr-4 pt-3 flex items-center justify-between text-sm">
                <div class="w-0 flex-1 flex items-center">
                  <span class="flex-shrink-0 h-5 text-gray-400">
                    Kecamatan
                  </span>
                  <span class="ml-2 flex-1 w-0 truncate">
                    {{ pesertadidik.wilayah?.nm_wil }}
                  </span>
                </div>
              </li>
              <li class="pr-4 pt-3 flex items-center justify-between text-sm">
                <div class="w-0 flex-1 flex items-center">
                  <span class="flex-shrink-0 h-5 text-gray-400">
                    Kewarganegaran
                  </span>
                  <span class="ml-2 flex-1 w-0 truncate">
                    {{ pesertadidik.negara?.nm_negara }}
                  </span>
                </div>
              </li>
            </ul>
          </template>
        </DataList>

        <DataList
          :white="true"
          :dd="
            pesertadidik.tmpt_lahir + ', ' + $h.tanggal(pesertadidik.tgl_lahir)
          "
          :dt="'Tempat/Tanggal Lahir'"
        ></DataList>
        <DataList
          :white="true"
          :dd="pesertadidik.agama?.nm_agama"
          :dt="'Agama'"
        ></DataList>
        <DataList :white="true" :dd="pesertadidik.nik" :dt="'NIK'"></DataList>
        <DataList :white="true" :dd="pesertadidik.npwp" :dt="'NPWP'"></DataList>
        <DataList :white="true" :dd="pesertadidik.nisn" :dt="'NISN'"></DataList>
        <DataList
          :white="true"
          :dd="pesertadidik.no_hp"
          :dt="'No HP'"
        ></DataList>
        <DataList
          :white="true"
          :dd="pesertadidik.no_tel_rmh"
          :dt="'No Telp'"
        ></DataList>
        <DataList
          :white="true"
          :dd="pesertadidik.email"
          :dt="'Email'"
        ></DataList>
      </dl>
      <dl class="col-span-12 lg:col-span-6">
        <DataList
          :gray="true"
          :dd="pesertadidik.nm_ibu_kandung"
          :dt="'Nama Ibu Kandung'"
        ></DataList>
        <DataList
          :gray="false"
          :dd="pesertadidik.nik_ibu"
          :dt="'NIK Ibu Kandung'"
        ></DataList>
        <DataList
          :gray="false"
          :dd="pesertadidik?.jenjang_pendidikan_ibu?.nm_jenj_didik"
          :dt="'Pendidikan Ibu'"
        ></DataList>
        <DataList
          :gray="false"
          :dd="pesertadidik?.pekerjaan_ibu?.nm_pekerjaan"
          :dt="'Pekerjaan Ibu'"
        ></DataList>
        <DataList
          :gray="false"
          :dd="pesertadidik?.penghasilan_ibu?.nm_penghasilan"
          :dt="'Penghasilan Ibu'"
        ></DataList>

        <DataList
          :gray="true"
          :dd="pesertadidik.nm_ayah"
          :dt="'Nama Ayah'"
        ></DataList>
        <DataList
          :gray="false"
          :dd="pesertadidik.nik_ayah"
          :dt="'NIK Ayah'"
        ></DataList>

        <DataList
          :gray="false"
          :dd="pesertadidik?.jenjang_pendidikan_ayah?.nm_jenj_didik"
          :dt="'Pendidikan Ayah'"
        ></DataList>
        <DataList
          :gray="false"
          :dd="pesertadidik?.pekerjaan_ayah?.nm_pekerjaan"
          :dt="'Pekerjaan Ayah'"
        ></DataList>
        <DataList
          :gray="false"
          :dd="pesertadidik?.penghasilan_ayah?.nm_penghasilan"
          :dt="'Penghasilan Ayah'"
        ></DataList>

        <DataList
          :gray="true"
          :dd="pesertadidik.nm_wali"
          :dt="'Wali'"
        ></DataList>

        <DataList
          :gray="false"
          :dd="pesertadidik?.jenjang_pendidikan_wali?.nm_jenj_didik"
          :dt="'Pendidikan Wali'"
        ></DataList>
        <DataList
          :gray="false"
          :dd="pesertadidik?.pekerjaan_wali?.nm_pekerjaan"
          :dt="'Pekerjaan Wali'"
        ></DataList>
        <DataList
          :gray="false"
          :dd="pesertadidik?.penghasilan_wali?.nm_penghasilan"
          :dt="'Penghasilan Wali'"
        ></DataList>

        <DataList
          :gray="true"
          :dd="pesertadidik?.jenis_tinggal?.nm_jns_tinggal"
          :dt="'Jenis Tinggal'"
        ></DataList>

        <DataList
          :gray="true"
          :dd="pesertadidik?.alat_transport?.nm_alat_transport"
          :dt="'Alat Transportasi'"
        ></DataList>
        <DataList
          :gray="true"
          :dd="pesertadidik?.a_terima_kps == '1' ? 'Ya' : 'Tidak'"
          :dt="'Penerima KPS'"
        >
          <template v-if="pesertadidik?.a_terima_kps == '1'" #dd>
            <ul role="list" class="">
              <li class="pr-4 py-3 flex items-center justify-between text-sm">
                <div class="w-0 flex-1 flex items-center">
                  <span class="flex-shrink-0 text-gray-400"> No. KPS </span>
                  <span class="ml-2 flex-1 w-0 truncate">
                    {{ pesertadidik.no_kps }}
                  </span>
                </div>
              </li>
            </ul>
          </template>
        </DataList>
      </dl>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import DataList from "@/views/mahasiswa/pesertadidik/component/DataList";

export default defineComponent({
  components: {
    DataList,
  },
  props: {
    pesertadidik: {
      type: Object,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      loading: true,
    };
  },
  computed: {
    loadingStatus() {
      return this.$store.state.main.loadingStatus;
    },
  },
  watch: {
    pesertadidik: {
      handler: function (newData) {
        if (newData?.id_pd !== undefined) {
          this.loading = false;
        }
      },
      immediate: true,
    },
  },
});
</script>

<style></style>
