<template>
  <div class="relative" style="min-height: 300px">
    <div v-if="loading" class="block-loading">
      <LoadingIcon icon="three-dots" class="w-8 h-8" />
      <div class="text-center mt-2">
        Data Sedang Di Proses, Silahkan Tunggu...
      </div>
    </div>
    <div class="col-span-1">
      <AlertSuccess v-if="alertSuccess" :message="messageAlert" />
      <AlertFailed v-if="alertFailed" :message="messageAlert" />
    </div>
    <table class="table">
      <thead>
        <tr class="bg-gray-700 dark:bg-dark-1 text-white">
          <th class="whitespace-nowrap">#</th>
          <th
            class="whitespace-nowrap cursor-pointer"
            @click="setSorting('kode_mk')"
          >
            KODE MK
            <ChevronDownIcon
              v-if="sorting.by === 'kode_mk' && sorting.dir === 'asc'"
              class="w-4 h-4 ml-1"
            ></ChevronDownIcon>
            <ChevronUpIcon
              v-else-if="sorting.by === 'kode_mk' && sorting.dir === 'desc'"
              class="w-4 h-4 ml-1"
            ></ChevronUpIcon>
          </th>
          <th
            class="whitespace-nowrap cursor-pointer"
            @click="setSorting('nm_mk')"
          >
            NAMA MK
            <ChevronDownIcon
              v-if="sorting.by === 'nm_mk' && sorting.dir === 'asc'"
              class="w-4 h-4 ml-1"
            ></ChevronDownIcon>
            <ChevronUpIcon
              v-else-if="sorting.by === 'nm_mk' && sorting.dir === 'desc'"
              class="w-4 h-4 ml-1"
            ></ChevronUpIcon>
          </th>
          <th
            class="whitespace-nowrap cursor-pointer"
            @click="setSorting('smt_ke')"
          >
            SMT AMBIL
            <ChevronDownIcon
              v-if="sorting.by === 'smt_ke' && sorting.dir === 'asc'"
              class="w-4 h-4 ml-1"
            ></ChevronDownIcon>
            <ChevronUpIcon
              v-else-if="sorting.by === 'smt_ke' && sorting.dir === 'desc'"
              class="w-4 h-4 ml-1"
            ></ChevronUpIcon>
          </th>
          <th class="whitespace-nowrap">SKS</th>
          <th class="whitespace-nowrap">NILAI HURUF</th>
          <th class="whitespace-nowrap">NILAI INDEX</th>
          <th class="whitespace-nowrap">MUTU</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in dataTranskrip"
          :key="index"
          class="hover:bg-gray-200 dark:hover:bg-gray-800"
        >
          <td class="border-b dark:border-dark-5">{{ index + 1 }}</td>
          <td class="border-b dark:border-dark-5">
            {{ item.kodeMk }}
          </td>
          <td class="border-b dark:border-dark-5">
            {{ item.namaMk }}
          </td>
          <td class="border-b dark:border-dark-5">
            {{ item.smt_ke }}
          </td>
          <td class="border-b dark:border-dark-5">{{ parseInt(item?.sks) }}</td>
          <td class="border-b dark:border-dark-5">{{ item?.nilaiHuruf }}</td>
          <td class="border-b dark:border-dark-5">{{ item?.nilaiIndex }}</td>
          <td class="border-b dark:border-dark-5">{{ item?.mutu }}</td>
        </tr>
        <tr v-if="dataTranskrip.length === 0">
          <td colspan="8" class="text-center">Tidak Ada Transkrip</td>
        </tr>
      </tbody>
      <tfoot v-if="dataTranskrip.length !== 0">
        <tr class="bg-gray-200 text-gray-700 dark:bg-gray-800 dark:text-white">
          <th class="whitespace-nowrap" colspan="4">Jumlah</th>
          <th class="whitespace-nowrap">{{ totalSks }} SKS</th>
          <th class="whitespace-nowrap"></th>
          <th class="whitespace-nowrap">Nilai Index</th>
          <th class="whitespace-nowrap">
            {{
              Math.round(
                (Number.parseFloat(totalMutu) + Number.EPSILON) * 100
              ) / 100
            }}
          </th>
          <th v-if="isaction" class="whitespace-nowrap"></th>
        </tr>
        <tr class="bg-gray-300 text-gray-700 dark:bg-gray-800 dark:text-white">
          <th class="whitespace-nowrap" colspan="7">
            Indeks Prestasi Kumulatif (IPK)
          </th>
          <th class="whitespace-nowrap">{{ ipk }}</th>
          <th v-if="isaction" class="whitespace-nowrap"></th>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import { perkuliahan } from "@/services/models";
import AlertSuccess from "../../components/alerts/AlertSuccess";
import AlertFailed from "../../components/alerts/AlertFailed.vue";
import SlideOver from "@/layouts/main/SlideOver";
export default {
  name: "NilaiTranskrip",
  components: {
    SlideOver,
    AlertSuccess,
    AlertFailed,
  },
  props: {
    kodemahasiswa: {
      type: String,
      default: "",
    },
    detailmahasiswa: {
      type: Object,
      default: null,
    },
    isaction: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      dataTranskrip: [],
      totalSks: 0,
      totalMutu: 0,
      ipk: 0,
      panelOpen: false,
      tmpDataEdit: [],
      idDelete: null,
      alertSuccess: false,
      alertFailed: false,
      messageAlert: "",
      sorting: {
        by: "kode_mk",
        dir: "asc",
      },
    };
  },
  watch: {
    detailmahasiswa: {
      handler: function () {
        this.getTranskrip();
      },
      immediate: true,
    },
  },
  methods: {
    getTranskrip() {
      this.loading = true;
      this.dataTranskrip = [];
      this.totalSks = 0;
      this.totalMutu = 0;
      perkuliahan.NilaiTranskrip.getCustomPath(
        `getTranskripByRegPd/${this.detailmahasiswa?.id_reg_pd}`,
        { params: this.sorting }
      )
        .then((res) => {
          if (res.status === 200) {
            if (res.data?.data) {
              res.data.data.forEach((element) => {
                let mutu = (element?.nilai_indeks * element?.sks_mk).toFixed(2);
                this.dataTranskrip.push({
                  kodeMk: element?.matkul?.kode_mk,
                  namaMk: element?.matkul?.nm_mk?.toUpperCase(),
                  sks: element?.sks_mk,
                  nilaiHuruf: element?.nilai_huruf,
                  nilaiAngka: element?.nilai_angka,
                  nilaiIndex: element?.nilai_indeks,
                  mutu: mutu,
                  id: element?.id,
                  smt_ke: element?.smt_ke,
                  id_mk: element?.id_mk,
                  kode_mk: element?.matkul?.kode_mk,
                  id_sms: this.detailmahasiswa?.id_sms,
                });
                this.totalSks += parseInt(element?.sks_mk);
                this.totalMutu += Number.parseFloat(mutu);
              });
              /*this.ipk = (
                Number.parseFloat(this.totalMutu) /
                Number.parseInt(this.totalSks)
              ).toFixed(2);*/
              let _ipk =
                Number.parseFloat(this.totalMutu) /
                Number.parseInt(this.totalSks);
              this.ipk = Math.round((_ipk + Number.EPSILON) * 100) / 100;
              this.loading = false;
            }
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    editData(item) {
      this.panelOpen = true;
      this.tmpDataEdit = item;
    },
    closePanel(close) {
      this.panelOpen = close;
    },
    destroy(id) {
      this.idDelete = id;
      cash("#delete-modal").modal("show");
    },
    deleteRow() {
      this.alertFailed = false;
      this.alertSuccess = false;
      this.loading = true;
      perkuliahan.NilaiTranskrip.deleteData(this.idDelete).then((res) => {
        if (res.status === 200) {
          this.alertSuccess = true;
          this.messageAlert = res?.data?.message;
          this.getTranskrip();
        } else {
          this.loading = false;
        }
      });
      cash("#delete-modal").modal("hide");
    },
    setSorting(by) {
      this.sorting.by = by;
      this.sorting.dir = this.sorting.dir === "asc" ? "desc" : "asc";
      this.getTranskrip();
    },
    compare(a, b) {
      const bandA = a.namaMk.toUpperCase();
      const bandB = b.namaMk.toUpperCase();

      let comparison = 0;
      if (bandA > bandB) {
        comparison = 1;
      } else if (bandA < bandB) {
        comparison = -1;
      }
      return comparison;
    },
    cetakTranskrip() {
      let data = {
        dataTranskrip: this.dataTranskrip.sort(this.compare),
        detailMhs: this.detailmahasiswa,
        totalSks: this.totalSks,
        kumulatif: this.ipk,
      };
      perkuliahan.CetakTranskrip.postCustomPathBlob("download", data).then(
        (res) => {
          console.log(res.data);
          let blobFile = new Blob([res.data], { type: "application/pdf" });
          var fileURL = URL.createObjectURL(blobFile);
          window.open(fileURL);
        }
      );
    },
  },
};
</script>

<style scoped>
.block-loading {
  display: flex;
  position: absolute;
  background: #ffffffad;
  height: 100%;
  width: 100%;
  z-index: 99;
  align-items: center;
  font-weight: bold;
  font-size: 32px;
  flex-direction: column;
  padding-top: 5rem;
}
</style>
