<template>
  <div class="relative" style="min-height: 300px">
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y">
      <LoadingSpinner :is-loading="loadingStatus" />
      <div class="grid grid-cols-12">
        <div class="md:col-span-3 col-span-12 mb-4">
          <label class="form-label">Pilih Semester</label>
          <select
            v-model="semester"
            class="form-control focus:outline-none focus:ring-0"
          >
            <option
              v-for="smtr in listSemester"
              :key="smtr.id"
              :value="smtr.value"
              :selected="smtr.selected"
            >
              {{ smtr.text }}
            </option>
          </select>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="table-report table-report--tabulator"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from "vue";
import xlsx from "xlsx";
import feather from "feather-icons";
import { useStore } from "vuex";
import LoadingSpinner from "@/components/loading-spinner/Main";
import { tblTabulator } from "@/utils/tabulator";
import { master, perkuliahan } from "@/services/models";

export default defineComponent({
  components: {
    LoadingSpinner,
  },
  props: {
    detailMahasiswa: {
      type: Object,
      default: null,
    },
    smt: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    let deletingRow = ref();
    const idData = ref();
    const store = useStore();
    const tableRef = ref();
    const tabulator = ref();
    const filter = reactive({
      field: "nm_mk",
      type: "like",
      value: "",
    });
    const accessToken = store.getters["auth/accessToken"];
    const initTabulator = () => {
      let columns = [
        {
          formatter: "responsiveCollapse",
          width: 40,
          minWidth: 30,
          hozAlign: "center",
          resizable: false,
          headerSort: false,
        },
        { formatter: "rownum", hozAlign: "center", width: 40 },

        // For HTML table
        {
          title: "Kelas",
          width: 90,
          responsive: 0,
          field: "nm_kls",
          vertAlign: "middle",
          print: true,
          download: true,
          headerSort: false,
        },
        {
          title: "Nama MataKuliah",
          width: 350,
          responsive: 0,
          field: "nm_mk",
          hozAlign: "left",
          vertAlign: "middle",
          print: true,
          download: true,
          headerSort: false,
          formatter(cell) {
            return `<div>
              ${cell.getData().kode_mk} | ${cell.getData().nm_mk}
            </div>`;
          },
        },
        {
          title: "Nama Dosen",
          width: 300,
          responsive: 0,
          field: "nama_dosen",
          hozAlign: "left",
          vertAlign: "middle",
          print: true,
          download: true,
          headerSort: false,
          formatter(cell) {
            return `<div>
            ${cell.getData().kode_dosen} | ${cell.getData().nama_dosen}
            </div>`;
          },
        },
        {
          title: "SKS",
          width: 100,
          field: "sks_mk",
          hozAlign: "center",
          vertAlign: "middle",
          print: true,
          download: true,
          headerSort: false,
          formatter(cell) {
            return `<div>
            ${cell.getData().sks_mk} SKS
            </div>`;
          },
        },
        {
          title: "Hadir",
          width: 100,
          field: "hadir",
          hozAlign: "center",
          vertAlign: "middle",
          print: true,
          download: true,
          headerSort: false,
          formatter(cell) {
            return `<div>
            ${cell.getData().hadir}
            </div>`;
          },
        },
        {
          title: "Izin",
          width: 100,
          field: "izin",
          hozAlign: "center",
          vertAlign: "middle",
          print: true,
          download: true,
          headerSort: false,
          formatter(cell) {
            return `<div>
            ${cell.getData().izin}
            </div>`;
          },
        },
        {
          title: "Alpha",
          width: 100,
          field: "alpha",
          hozAlign: "center",
          vertAlign: "middle",
          print: true,
          download: true,
          headerSort: false,
          formatter(cell) {
            return `<div>
            ${cell.getData().alpha}
            </div>`;
          },
        },
        {
          title: "Total Pertemuan",
          field: "jumlah_pertemuan",
          hozAlign: "center",
          vertAlign: "middle",
          print: true,
          download: true,
          headerSort: false,
          formatter(cell) {
            return `<div>
            ${cell.getData().jumlah_pertemuan}
            </div>`;
          },
        },
        {
          title: "Persentase",
          field: "jumlah_pertemuan",
          hozAlign: "center",
          vertAlign: "middle",
          width: 150,
          print: true,
          download: true,
          headerSort: false,
          formatter(cell) {
            let hitungPersenAbsen =
              ((cell.getData().hadir * 4 + cell.getData().izin * 1) /
                (cell.getData().jumlah_pertemuan * 4)) *
              100;
            return `<div>
            ${parseFloat(hitungPersenAbsen).toFixed(2)} %
            </div>`;
          },
        },
      ];
      tabulator.value = tblTabulator.remoteDataTable(
        tableRef,
        perkuliahan.kuliahMhs.endpoint +
          "/filterListMhsKelas/" +
          props.detailMahasiswa?.id_reg_pd,
        columns,
        true
      );
    };
    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener("resize", () => {
        tabulator.value.redraw();
        feather.replace({
          "stroke-width": 1.5,
        });
      });
    };
    // Filter function
    const onFilter = () => {
      tabulator.value.setFilter(filter.field, filter.type, filter.value);
    };
    // On reset filter
    const onResetFilter = () => {
      filter.field = "nm_mk";
      filter.type = "like";
      filter.value = "";
      onFilter();
    };
    // Export
    const onExportCsv = () => {
      tabulator.value.download(
        "csv",
        `RekapAbsensi${new Date().toDateString()}.csv`
      );
    };
    const onExportJson = () => {
      tabulator.value.download(
        "json",
        `RekapAbsensi${new Date().toDateString()}.json`
      );
    };
    const onExportXlsx = () => {
      const win = window;
      win.XLSX = xlsx;
      tabulator.value.download(
        "xlsx",
        `RekapAbsensi${new Date().toDateString()}.xlsx`,
        {
          sheetName: "Products",
        }
      );
    };
    const onExportHtml = () => {
      tabulator.value.download(
        "html",
        `RekapAbsensi${new Date().toDateString()}.html`,
        {
          style: true,
        }
      );
    };
    // Print
    const onPrint = () => {
      tabulator.value.print();
    };
    onMounted(() => {
      initTabulator();
      reInitOnResizeWindow();
    });
    return {
      store,
      tabulator,
      tableRef,
      filter,
      onFilter,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      accessToken,
      deletingRow,
      idData,
    };
  },
  data() {
    return {
      semester: null,
      listSemester: [],
    };
  },
  computed: {
    loadingStatus() {
      return this.$store.state.main.loadingStatus;
    },
    currentSemester() {
      return this.$store.state.main.currentSemester;
    },
  },
  watch: {
    //update table if data changes
    dataTable: {
      handler: function (newData) {
        if (
          this.tabulator.getDataCount() !== newData.length &&
          newData.length > 0
        ) {
          this.replaceData(newData);
        } else if (newData.length < this.tabulator.getDataCount()) {
          this.replaceData(newData);
        }
      },
      deep: true,
    },
    smt: {
      handler: function (newval) {
        this.semester = newval;
      },
      immediate: true,
    },
    kodeMahasiswa: {
      handler: function () {
        this.fetchSemester();
      },
      immediate: true,
    },
    currentSemester: {
      handler: function () {
        this.$store.state.main.currentSemester;
      },
    },

    semester: {
      handler: function () {
        this.getItems();
      },
    },
  },
  methods: {
    getItems() {
      this.tabulator.setData(
        perkuliahan.kuliahMhs.endpoint +
          "/filterListMhsKelas/" +
          this.detailMahasiswa?.id_reg_pd,
        { semester: this.semester },
        tblTabulator.ajaxConfig()
      );
    },
    fetchSemester() {
      let that = this;
      this.listSemester = [];
      this.listSemester.push({
        id: "0",
        value: "0",
        text: "Pilih Semester",
        selected: false,
      });
      let path =
        this.detailMahasiswa?.id_reg_pd === undefined
          ? "smt_kuliah_mhs"
          : "smt_kuliah_mhs/" + this.detailMahasiswa?.id_reg_pd;

      master.SemesterAkad.getCustomPath(path).then((result) => {
        if (result.status === 200) {
          result.data.data.forEach((item) => {
            let newoption = {
              id: item.id_smt,
              value: item.id_smt,
              text: item.nm_smt,
              selected: that.semester.toString() === item.id_smt,
            };

            if (newoption.selected === true)
              that.semester = item.id_smt.toString();
            that.listSemester.push(newoption);
          });
        }
      });
    },
  },
});
</script>
