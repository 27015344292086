<template>
  <div>
    <div class="intro-y box p-3">
      <InfoMahasiswa
        v-if="showMhsInfo"
        :show-contact="false"
        :pesertadidik="infoMhs"
        :dosen-p-a="dosenPA"
        :kuliah-mhs-last="kuliahMhsLast"
      />
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start mt-3">
        <form
          id="tabulator-html-filter-form"
          class="sm:flex sm:mr-auto"
          @submit.prevent="onFilter"
        >
          <div class="sm:flex items-center sm:mr-4">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
              >Field</label
            >
            <select
              id="tabulator-html-filter-field"
              v-model="filter.field"
              class="form-select w-full sm:w-32 xxl:w-full mt-2 sm:mt-0 sm:w-auto"
            >
              <option value="nm_mk">Nama Matakuliah</option>
            </select>
          </div>
          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
              >Value</label
            >
            <input
              id="tabulator-html-filter-value"
              v-model="filter.value"
              type="text"
              class="form-control sm:w-40 xxl:w-full mt-2 sm:mt-0"
              placeholder="Search..."
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full sm:w-16"
              @click="onFilter"
            >
              Go
            </button>
          </div>
        </form>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5 table-report table-report--tabulator"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from "vue";
import { useStore } from "vuex";
import feather from "feather-icons";
import { perkuliahan } from "@/services/models";
import { mahasiswa } from "@/services/models";
import InfoMahasiswa from "@/components/mahasiswa/InfoMahasiswa";
import { tblTabulator } from "@/utils/tabulator";

export default defineComponent({
  name: "ListNilaiTransferSP",
  components: { InfoMahasiswa },
  props: {
    showMhsInfo: {
      type: Boolean,
      default: true,
    },
    idregpd: {
      type: String,
      default: "",
    },
    idpd: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    let deletingRow = ref();
    const idData = ref();
    const store = useStore();
    const tableRef = ref();
    const tabulator = ref();
    const filter = reactive({
      field: "nm_mk",
      type: "like",
      value: "",
    });
    const accessToken = store.getters["auth/accessToken"];

    let panel = reactive({ panelNilaiTransfer: ref(), panelDetailMhs: ref() });

    const initTabulator = () => {
      let columns = [
        {
          formatter: "responsiveCollapse",
          width: 40,
          minWidth: 30,
          hozAlign: "center",
          resizable: false,
          headerSort: false,
        },
        // For HTML table
        {
          title: "Matakuliah Asal",
          minWidth: 300,
          field: "matkul.nm_mk",
          hozAlign: "left",
          vertAlign: "top",
          print: true,
          download: true,
          headerSort: false,
          formatter(cell) {
            return `<div>
                <div class="font-medium whitespace-nowrap">${
                  cell.getData().kode_mk_asal
                }
                <i data-feather="check-square" class="w-4 h-4 ml-1"></i>
                </div>
                <div class="text-gray-600 text-xs whitespace-normal">${
                  cell.getData().nm_mk_asal
                }</div>
              </div>`;
          },
        },
        {
          title: "Nilai Asal",
          field: "sks_asal",
          hozAlign: "left",
          vertAlign: "top",
          print: true,
          download: true,
          headerSort: false,
          formatter(cell) {
            return `<div>
                <div class="text-xs font-medium whitespace-nowrap">
                SKS : <span class="text-gray-600">${parseInt(
                  cell.getData().sks_asal
                )}</span>
                </div>
                <div class="text-xs font-medium whitespace-nowrap">
                Nilai Huruf : <span class="text-gray-600">${
                  cell.getData().nilai_huruf_asal
                }</span>
                </div>

              </div>`;
          },
        },
        {
          title: "Matakuliah Diakui",
          minWidth: 300,
          field: "matkul.nm_mk",
          hozAlign: "left",
          vertAlign: "top",
          print: true,
          download: true,
          headerSort: false,
          formatter(cell) {
            return `<div>
                <div class="font-medium whitespace-nowrap">${
                  cell.getData().matkul.kode_mk
                }
                <i data-feather="check-square" class="w-4 h-4 ml-1"></i>
                </div>
                <div class="text-gray-600 text-xs whitespace-normal">${
                  cell.getData().matkul.nm_mk
                }</div>
              </div>`;
          },
        },
        {
          title: "Nilai Diakui",
          field: "sks_diakui",
          hozAlign: "left",
          vertAlign: "top",
          print: true,
          download: true,
          headerSort: false,
          formatter(cell) {
            return `<div>
                <div class="text-xs font-medium whitespace-nowrap">
                SKS : <span class="text-gray-600">${parseInt(
                  cell.getData().sks_diakui
                )}</span>
                </div>
                <div class="text-xs font-medium whitespace-nowrap">
                Nilai Huruf : <span class="text-gray-600">${
                  cell.getData().nilai_huruf_diakui
                }</span>
                </div>
                <div class="text-xs font-medium whitespace-nowrap">
                Nilai Index : <span class="text-gray-600">${
                  cell.getData().nilai_indeks_diakui
                }</span>
                </div>

              </div>`;
          },
        },
      ];
      tabulator.value = tblTabulator.remoteDataTable(
        tableRef,
        perkuliahan.EkuivTransfer.endpoint +
          "/getNilaiTransferSp/" +
          props.idregpd,
        columns,
        true
      );
    };
    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener("resize", () => {
        tabulator.value.redraw();
        feather.replace({
          "stroke-width": 1.5,
        });
      });
    };
    // Filter function
    const onFilter = () => {
      tabulator.value.setFilter(filter.field, filter.type, filter.value);
    };
    // On reset filter
    const onResetFilter = () => {
      filter.field = "nm_mk";
      filter.type = "like";
      filter.value = "";
      onFilter();
    };
    onMounted(() => {
      initTabulator();
      reInitOnResizeWindow();
    });
    return {
      store,
      tabulator,
      tableRef,
      filter,
      onFilter,
      onResetFilter,
      accessToken,
      deletingRow,
      idData,
      panel,
    };
  },

  data() {
    return {
      dataMhs: null,
      infoMhs: [],
      dosenPA: [],
      kuliahMhsLast: null,
    };
  },
  mounted() {
    this.getDosenPA();
  },
  methods: {
    async getDosenPA() {
      mahasiswa.PesertaDidik.getData(`${this.idpd}`)
        .then((res) => {
          if (res.status === 200) {
            this.infoMhs = res.data.data;
            this.dosenPA = res.data.data?.map_mhs;
          }
        })
        .finally(() => {
          if (this.infoMhs?.reg_pd?.id_reg_pd?.length > 0) {
            perkuliahan.kuliahMhs
              .fetchUrl("latestKuliah/" + this.infoMhs?.reg_pd?.id_reg_pd)
              .then((result) => {
                if (result?.status === 200) {
                  this.kuliahMhsLast = result.data.data;
                }
              });
          }
        });
    },
  },
});
</script>
