<template>
  <div class="p-2">
    <select v-model="current_IdRegPD" class="form-select mb-3">
      <option disabled value="">Pilih Program Studi</option>
      <option
        v-for="(reg, index) in regPD"
        :key="index"
        :value="reg?.id_reg_pd"
      >
        {{ reg?.sms?.jenjang_pendidikan.nm_jenj_didik }}
        {{ reg?.sms?.nm_lemb }} - {{ reg?.satuan_pendidikan?.nm_lemb }}
      </option>
    </select>
    <table class="table">
      <thead>
        <tr class="bg-gray-700 dark:bg-dark-1 text-white">
          <th class="whitespace-nowrap">#</th>
          <th class="whitespace-nowrap">Semester</th>
          <th class="whitespace-nowrap">Status</th>
          <th class="whitespace-nowrap">IPS</th>
          <th class="whitespace-nowrap">IPK</th>
          <th class="whitespace-nowrap">SKS</th>
          <th class="whitespace-nowrap">SKS Total</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(pd, key) in kuliahMhs"
          :key="pd.id"
          class="hover:bg-gray-200 dark:hover:bg-dark-1"
        >
          <td class="border-b dark:border-dark-5">{{ ++key }}</td>

          <td class="border-b dark:border-dark-5">
            {{ pd?.semester?.nm_smt }}
          </td>
          <td class="border-b dark:border-dark-5">
            {{ pd?.status_mahasiswa?.nm_stat_mhs }}
          </td>
          <td class="border-b dark:border-dark-5">
            {{ parseFloat(pd.ips).toFixed(2) }}
          </td>
          <td class="border-b dark:border-dark-5">
            {{ parseFloat(pd.ipk).toFixed(2) }}
          </td>
          <td class="border-b dark:border-dark-5">
            {{ parseInt(pd.sks_smt) }}
          </td>
          <td class="border-b dark:border-dark-5">
            {{ parseInt(pd.sks_total) }}
          </td>
        </tr>
      </tbody>
    </table>

    <div v-if="keluar != null" class="mt-5">
      <h4 class="font-medium mr-auto">Detail Peserta Didik</h4>
      <div class="mt-2">
        <div class="flex flex-col sm:flex-row">
          <div class="mr-10 w-40">
            <div class="text-gray-600 mt-1">Status Keluar</div>
          </div>
          <div class="flex">
            <div class="text-left">
              <div class="font-medium mt-1">
                {{ detailKeluar.jenis_keluar.ket_keluar }}
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col sm:flex-row">
          <div class="mr-10 w-40">
            <div class="text-gray-600 mt-1">Tanggal Keluar</div>
          </div>
          <div class="flex">
            <div class="text-left">
              <div class="font-medium mt-1">
                {{ detailKeluar.tgl_keluar }}
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-col sm:flex-row">
          <div class="mr-10 w-40">
            <div class="text-gray-600 mt-1">Jalur Skripsi</div>
          </div>
          <div class="flex">
            <div class="text-left">
              <div class="bg-theme-18 text-theme-9 rounded px-2 mt-1">
                {{
                  parseInt(detailKeluar.jalur_skripsi) === 1 ? "YA" : "TIDAK"
                }}
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-col sm:flex-row">
          <div class="mr-10 w-40">
            <div class="text-gray-600 mt-1">Judul Skripsi</div>
          </div>
          <div class="flex">
            <div class="text-left">
              <div class="font-medium mt-1">
                {{ detailKeluar.judul_skripsi }}
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col sm:flex-row">
          <div class="mr-10 w-40">
            <div class="text-gray-600 mt-1">SK Yudisium</div>
          </div>
          <div class="flex">
            <div class="text-left">
              <div class="font-medium mt-1">
                {{ detailKeluar.sk_yudisium }}
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col sm:flex-row">
          <div class="mr-10 w-40">
            <div class="text-gray-600 mt-1">Smt Yudisium</div>
          </div>
          <div class="flex">
            <div class="text-left">
              <div class="font-medium mt-1">
                {{ detailKeluar.smt_yudisium }}
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col sm:flex-row">
          <div class="mr-10 w-40">
            <div class="text-gray-600 mt-1">No. Seri Ijazah</div>
          </div>
          <div class="flex">
            <div class="text-left">
              <div class="font-medium mt-1">
                {{ detailKeluar.no_seri_ijazah }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { perkuliahan } from "@/services/models";

export default {
  name: "DataKuliahMahasiswa",
  components: {},
  props: {
    regPD: {
      type: Object,
      default: null,
    },
    idRegPD: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      detailKeluar: null,
      keluar: null,
      kuliahMhs: null,
      current_IdRegPD: "",
    };
  },
  watch: {
    regPD: {
      handler: function () {},
      immediate: true,
    },
    idRegPD: {
      handler: function (newid) {
        if (newid !== null) {
          this.current_IdRegPD = newid;
        }
      },
      immediate: true,
    },
    current_IdRegPD: {
      handler: function (newid) {
        let that = this;
        if (newid != null && newid != 0) {
          perkuliahan.kuliahMhs.fetchUrl("get/" + newid).then((result) => {
            if (result?.status === 200) {
              that.kuliahMhs = result.data.data;
            }
          });
        }
      },
      immediate: true,
    },
  },
  mounted() {
    //this.current_IdRegPD = 0;
  },
  methods: {
    setDetailKeluar(keluar, detail) {
      this.detailKeluar = detail;
      this.keluar = keluar;
    },
  },
};
</script>

<style scoped></style>
