<template>
  <div class="relative">
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Ubah Beasiswa Mahasiswa</h2>
    </div>

    <div class="intro-y box p-5 mt-5">
      <LoadingSpinner :is-loading="loadingStatus" />
      <div class="grid sm:grid-cols-3 grid-cols-1 gap-2">
        <div class="mb-2">
          <label class="form-label">NPM</label>
          <input v-model="nipd" class="form-control disabled" disabled />
        </div>

        <div class="mb-2">
          <label class="form-label">Tahun Akademik</label>
          <select v-model="smt" class="form-select">
            <option
              v-for="smtr in semester"
              :key="smtr.id"
              :value="smtr.value"
              :selected="smtr.selected"
            >
              {{ smtr.text }}
            </option>
          </select>
          <span
            v-if="
              messageValidator?.thnakademik &&
              messageValidator?.thnakademik.length > 0
            "
            class="text-red-600"
            >{{ messageValidator?.thnakademik[0] }}</span
          >
        </div>

        <div class="mb-2">
          <label class="form-label">Jenis</label>
          <select
            v-model="jenis"
            class="form-select"
          >
            <option
              v-for="item in listJenis"
              :key="item.id"
              :value="item.value"
            >
              {{ item.text }}
            </option>
          </select>
          <span
            v-if="messageValidator?.jenis && messageValidator?.jenis.length > 0"
            class="text-red-600"
            >{{ messageValidator?.jenis[0] }}</span
          >
        </div>

        <div class="mr-2">
          <label class="form-label">Nominal</label>
          <input
            v-model="nominal"
            type="number"
            class="form-control"
            rows="4"
            @keyup="formatRupiah(nominal)"
          />
          <span
            v-if="
              messageValidator?.nominal && messageValidator?.nominal.length > 0
            "
            class="text-red-600"
            >{{ messageValidator?.nominal[0] }}</span
          >
          <span v-else class="text-green-800 font-medium">
            {{ rupiahNominal }}
          </span>
        </div>

        <div class="mb-2">
          <label class="form-label">Akun (pilih jika ada perubahan)</label>
          <AutoComplete
            v-model="selectedAkun"
            :suggestions="filteredAkun"
            field="label"
            force-selection
            placeholder="Ketikkan Nama Akun atau Kode Akun"
            class="w-full remake"
            @focus="loadDataAkun"
            @complete="searchAkun($event)"
          >
            <template #item="slotProps">
              <div class="item">
                <div>
                  <strong> {{ slotProps.item?.label }}</strong>
                  {{ slotProps.item?.kd_account }}
                </div>
              </div>
            </template>
          </AutoComplete>
          <div class="text-gray-600 text-sm font-semibold">
            Pilihan Sebelumnya : {{ akunsebelumnya }}
          </div>
          <span
            v-if="messageValidator?.jenis && messageValidator?.jenis.length > 0"
            class="text-red-600"
            >{{ messageValidator?.jenis[0] }}</span
          >
        </div>

        <div class="form-check mb-2">
          <input
            id="valid"
            v-model="validasi"
            value="0"
            type="checkbox"
            class="form-check-input"
            :checked="validasi == 1"
          />
          <label class="form-check-label" for="valid">Validasi</label>
        </div>
      </div>

      <div class="mr-2">
        <label class="form-label">Keterangan</label>
        <textarea v-model="keterangan" class="form-control" rows="4"></textarea>
      </div>

      <div class="my-3">
        <button class="btn btn-primary w-full" @click="simpanBeasiswa">
          SIMPAN
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { HTTPKEU } from "../../../services/http";
import { master, keuangan } from "@/services/models";
import router from "@/router";
import LoadingSpinner from "../../../components/loading-spinner/Main";

export default defineComponent({
  components: {
    LoadingSpinner,
  },
  setup() {
    const route = useRoute();
    const id = route.params.id; // read parameter id (it is reactive)
    const store = useStore();
    return {
      store,
      id,
    };
  },
  data() {
    return {
      dataBeasiswa: {},
      nipd: "",
      semester: [],
      smt: "",
      listJenis: [],
      jenis: "",
      keterangan: "",
      nominal: "",
      validasi: 0,
      akunsebelumnya: "",
      messageValidator: [],
      listAkun: null,
      filteredAkun: null,
      selectedAkun: [],
      rupiahNominal: "",
    };
  },
  computed: {
    loadingStatus() {
      return this.$store.state.main.loadingStatus;
    },
  },
  mounted() {
    this.getDataBeasiswa();
    this.jenislist();
    this.fetchSemester();
  },
  methods: {
    formatRupiah(nominal) {
      if (nominal) {
        let rupiahLocal = Intl.NumberFormat("id-ID");
        this.rupiahNominal = "Rp." + rupiahLocal.format(nominal);
      } else {
        this.rupiahNominal = "";
      }
    },
    getDataBeasiswa() {
      keuangan.Beasiswa.get(this.id).then((res) => {
        if (res.data.code === 200) {
          //if (res.data.datas.validasi == 0) {
          let beasiswa = res.data.datas;
          this.nipd = `${beasiswa.reg_pd.peserta_didik.nm_pd} (${beasiswa.reg_pd.nipd})`;
          this.smt = beasiswa.id_smt;
          this.jenis = beasiswa.jenis;
          this.nominal = beasiswa.nominal;
          this.keterangan = beasiswa.keterangan;
          this.akunsebelumnya =
            beasiswa.accountan.account + " (" + beasiswa.account_code + ")";
          this.selectedAkun.kd_account = beasiswa.account_code;
          this.selectedAkun.account = beasiswa.accountan.account;
          this.selectedAkun.label =
            beasiswa.accountan.account + " (" + beasiswa.account_code + ")";
          this.validasi = beasiswa.validasi;
          /*} else {
            router.push({
              name: "beasiswa",
            });
          }*/
        } else {
          router.push({
            name: "beasiswa",
          });
        }
      });
    },
    fetchSemester() {
      let that = this;
      this.semester.push({
        id: "",
        value: "",
        text: "Pilih Semester",
        selected: false,
      });
      master.Semester.getAllActive().then((result) => {
        if (result.status === 200) {
          result.data.data.forEach((item) => {
            let newoption = {
              id: item.id_smt,
              value: item.id_smt,
              text: item.nm_smt,
              id_smt: item.id_smt,
              nm_smt: item.nm_smt,
              smt: item.smt,
              id_thn_ajaran: item.id_thn_ajaran,
              thn_ajaran: item.thn_ajaran,
              selected: that.current?.id_smt === item.id_smt,
            };

            that.semester.push(newoption);
          });
        }
      });
    },
    jenislist() {
      HTTPKEU.get("list/jenisbiaya").then((res) => {
        this.listJenis = [];
        let opt = {
          id: 0,
          value: "",
          text: "Pilih Jenis Beasiswa",
          selected: true,
        };
        this.listJenis.push(opt);

        res.data.forEach((item) => {
          const opt = {
            id: item.id,
            value: item.id,
            text: `${item.keterangan}`,
            selected: false,
          };
          this.listJenis.push(opt);
        });
      });
    },
    searchAkun(event) {
      setTimeout(() => {
        if (!event.query.length) {
          this.filteredAkun = [...this.listAkun];
        } else {
          keuangan.Akun.searchData({
            params: {
              q: event.query,
            },
          }).then((result) => {
            this.listAkun = result.data.data.map((act) => {
              return {
                kd_account: act.kd_account,
                account: act.account,
                label: act.account + " (" + act.kd_account + ")",
              };
            });
            this.filteredAkun = [...this.listAkun];
          });
        }
      }, 100);
    },
    loadDataAkun() {
      keuangan.Akun.searchData({}).then((result) => {
        this.listAkun = result?.data?.data?.map((acc) => {
          return {
            kd_account: acc.kd_account,
            accound: acc.account,
            label: acc.account + " (" + acc.kd_account + ")",
          };
        });
      });
    },
    simpanBeasiswa() {
      let val = {
        thnakademik: this.smt,
        jenis: this.jenis,
        keterangan: this.keterangan,
        nominal: this.nominal,
        akun: this.selectedAkun.kd_account,
        validasi: this.validasi,
      };
      keuangan.Beasiswa.updateData(this.id, val).then((res) => {
        if (res.data.code === 200) {
          router.push({
            name: "beasiswa",
          });
        } else {
          this.messageValidator = res.data.message;
        }
      });
    },
  },
});
</script>

<style scope>
.p-autocomplete > input {
  width: 100% !important;
}
.remake {
  padding: 0 !important;
}
</style>

<style scoped></style>
