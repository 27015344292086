export default {
  fields: [
    {
      type: "input",
      inputType: "text",
      label: "Nama Ibu",
      model: "nm_ibu_kandung",
      placeholder: "Nama Ibu",
    },
    {
      type: "dateTimePicker",
      inputType: "date",
      label: "Tanggal Lahir Ibu",
      model: "tgl_lahir_ibu",
      placeholder: "Tanggal Lahir Ibu",
      format: "YYYY.MM.DD",
      dateTimePickerOptions: {
        format: "YYYY-MM-DD",
      },
    },
    {
      type: "input",
      inputType: "text",
      label: "NIK Ibu",
      model: "nik_ibu",
      placeholder: "NIK Ibu",
    },
    {
      type: "select",
      inputType: "text",
      label: "Jenjang Pendidikan Ibu",
      model: "id_jenjang_pendidikan_ibu",
      placeholder: "Pilih Jenjang Pendidikan Ibu",
    },
    {
      type: "select",
      inputType: "text",
      label: "Pekerjaan Ibu",
      model: "id_pekerjaan_ibu",
      placeholder: "Pilih Pekerjaan Ibu",
    },
    {
      type: "select",
      inputType: "text",
      label: "Penghasilan Ibu",
      model: "id_penghasilan_ibu",
      placeholder: "Pilih Penghasilan Ibu",
    },
    // {
    //   type: "select",
    //   inputType: "text",
    //   label: "Kebutuhan Khusus Ibu",
    //   model: "id_kebutuhan_khusus_ibu",
    //   placeholder: "Pilih Kebutuhan Khusus Ibu"
    // },
    /*{
      type: "input",
      inputType: "text",
      label: "Kebutuhan Khusus Ibu",
      model: "id_kebutuhan_khusus_ibu",
      placeholder: "Pilih Kebutuhan Khusus Ibu",
    },*/
    // {
    //   type: "select",
    //   inputType: "text",
    //   label: "Kebutuhan Khusus",
    //   model: "id_kk",
    //   placeholder: "Kebutuhan Khusus"
    // },
    /*{
      type: "input",
      inputType: "text",
      label: "Kebutuhan Khusus",
      model: "id_kk",
      placeholder: "Kebutuhan Khusus",
    },*/
    {
      type: "input",
      inputType: "text",
      label: "No Telp Rumah",
      model: "no_tel_rmh",
      placeholder: "No Telp Rumah",
    },
    {
      type: "input",
      inputType: "text",
      label: "No HP",
      model: "no_hp",
      placeholder: "No HP",
    },
    {
      type: "input",
      inputType: "text",
      label: "Email",
      model: "email",
      placeholder: "Email",
    },
    {
      type: "radio",
      radioinline: true,
      label: "Terima KPS",
      model: "a_terima_kps",
      placeholder: "Terima KPS",
    },
    {
      type: "input",
      inputType: "text",
      label: "No KPS",
      model: "no_kps",
      placeholder: "No KPS",
    },
    /*{
      type: "input",
      inputType: "text",
      label: "Status Peserta Didik",
      model: "stat_pd",
      placeholder: "Status Peserta Didik",
    },*/
    {
      type: "input",
      inputType: "text",
      label: "NPWP",
      model: "npwp",
      placeholder: "NPWP",
    },
    {
      type: "select",
      inputType: "text",
      label: "Wilayah",
      model: "id_wil",
      placeholder: "Pilih Wilayah",
    },
    {
      type: "select",
      inputType: "text",
      label: "Jenis Tinggal",
      model: "id_jns_tinggal",
      placeholder: "Pilih Jenis Tinggal",
    },
    {
      type: "select",
      inputType: "text",
      label: "Agama",
      model: "id_agama",
      placeholder: "Pilih Agama",
    },
    {
      type: "select",
      inputType: "text",
      label: "Alat Transport",
      model: "id_alat_transport",
      placeholder: "Pilih Alat Transport",
    },
  ],
};
